import { simpleFetch } from './customFetch';

export const getInviteTotalFromInvitationApi = (options = {}) =>
  simpleFetch('/invites/users/self/active/count', options, process.env.REACT_APP_INVITATION_API);

export const getInviteTotalFromLegacyApi = (options = {}) =>
  simpleFetch(
    '/wp-admin/admin-ajax.php?action=fff_invite_friends',
    options,
    process.env.REACT_APP_SITE_URL
  );
