import { IRequest } from "../../interfaces";

async function request({
  path,
  method = 'GET',
  body,
  json = true,
  contentType = 'application/json; charset=utf-8',
}: IRequest): Promise<any> {
  const defaultHeaders = {
    'FFF-Auth': 'V1.1',
    'Content-Type': contentType,
  };

  const response = await fetch(path, {
    credentials: 'include',
    headers: { ...defaultHeaders },
    body: method !== 'GET' ? body : null,
    mode: 'cors',
    method,
  });
  const { status } = response;
  switch (status) {
    case 200:
      return json ? response.json() : response.text();
    case 201:
      return json ? response.json() : response.text();
    case 204:
      return '';
    case 400:
      return Promise.reject(new Error('Bad request'));
    case 401:
      return Promise.reject(new Error('Unauthorized'));
    case 403:
      return Promise.reject(new Error('Forbidden'));
    case 408:
      return Promise.reject(new Error('Timeout'));
    case 500:
      return Promise.reject(new Error('API internal error'));
    case 502:
      return Promise.reject(new Error('Proxy error'));
    case 503:
      return Promise.reject(new Error('Service unavailable'));
    case 504:
      return Promise.reject(new Error('Timeout'));
    default:
      return Promise.reject(new Error(`Unhandled HTTP code: ${status}`));
  }
}

export default request;
