/* eslint-disable */

import { useEffect } from 'react';

const getSegmentKey = (env) => {
  switch (env) {
    case 'local': {
      return process.env.SEGMENT_KEY_DEV;
    }
    case 'dev': {
      return process.env.SEGMENT_KEY_DEV;
    }
    case 'qa': {
      return process.env.SEGMENT_KEY_QA;
    }
    case 'load': {
      return process.env.SEGMENT_KEY_LOAD;
    }
    case 'load01': {
      return process.env.SEGMENT_KEY_LOAD01;
    }
    case 'prod': {
      return process.env.SEGMENT_KEY;
    }
    default: {
      return console.error('Global Cart is missing ENV prop used for Segment Analytics');
    }
  }
};

const useAnalytics = (env) => {
  useEffect(() => {
    //script was pulled from Pandora repo
    const segmentKey = getSegmentKey(env);
    const analytics = (global.window.analytics = global.window?.analytics || []);
    if (!analytics.initialize)
      if (analytics.invoked) {
          if (typeof global.window !== 'undefined') {
            global.window?.console && console.error && console.error('Segment snippet included twice.');
          }
      }
      else {
        analytics.invoked = !0;
        analytics.methods = [
          'trackSubmit',
          'trackClick',
          'trackLink',
          'trackForm',
          'pageview',
          'identify',
          'reset',
          'group',
          'track',
          'ready',
          'alias',
          'debug',
          'page',
          'once',
          'off',
          'on',
        ];
        analytics.factory = function (t) {
          return function () {
            const e = Array.prototype.slice.call(arguments);
            e.unshift(t);
            analytics.push(e);
            return analytics;
          };
        };
        for (let t = 0; t < analytics.methods.length; t++) {
          const e = analytics.methods[t];
          analytics[e] = analytics.factory(e);
        }
        analytics.load = function (t, e) {
          if (typeof global.window !== 'undefined') {
            const n = global.window?.document?.createElement('script');
            n.type = 'text/javascript';
            n.async = !0;
            n.src = `https://cdn.segment.com/analytics.js/v1/${t}/analytics.min.js`;
            const a = global.window?.document?.getElementsByTagName('script')[0];
            a.parentNode.insertBefore(n, a);
            analytics._loadOptions = e;
          }
        };
        analytics.SNIPPET_VERSION = '4.1.0';
        analytics.load(segmentKey);
      }
  }, []);
};

export default useAnalytics;
