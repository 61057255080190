import _ from 'lodash';
import { getWindowSize } from '../../shared/hooks/useWindowSize';

const windowSize = getWindowSize();

export const getCartItemTrackingData = (
  variant,
  index,
  userData,
  campaignData,
  total,
  cadenceType
) => {
  const {
    legacyPostId,
    name,
    imageUrl,
    salePrice,
    parentLegacyPostId,
    soldOut,
    price,
    categories,
    merchPositionOrder,
  } = variant;
  const { userId, subscriptionState, role, planCode } = userData;
  const { id: campaignId, name: campaignName } = campaignData;
  const trackingData = {
    product_id: legacyPostId,
    product_availability: soldOut ? 'OOS' : 'In Stock',
    parent_product_id: parentLegacyPostId,
    product_name: name,
    product_categories: categories,
    position_location: 'Cart',
    merch_position_order: merchPositionOrder,
    // position of item on page
    event_position_order: index + 1,
    retail_price: price,
    sale_price: salePrice,
    product_image_url: imageUrl,
    experience: 'Cart',
    total,
    user_id: userId,
    role: _.lowerCase(role),
    subscription_state: _.lowerCase(subscriptionState),
    campaign_id: campaignId,
    campaign_name: campaignName,
    plan_code: planCode,
    subscribable: !!cadenceType,
  };
  return trackingData;
};

export const getCartTrackingData = (userData, campaignData, cartData, tab, teamId) => {
  const { userId, role, subscriptionState, planCode } = userData;
  const { id: campaignId, name: campaignName } = campaignData;
  const { itemsCount, cartVariants, userIds } = cartData;
  const totalUnits =
    itemsCount || _.reduce(cartVariants, (total, variant) => total + variant.userQuantity, 0);

  return {
    user_id: userId,
    role: _.lowerCase(role),
    plan_code: planCode,
    subscription_state: _.lowerCase(subscriptionState),
    campaign_id: campaignId,
    campaign_name: campaignName,
    products: _.map(cartVariants, (v) => v.legacyPostId),
    totalProducts: _.size(cartVariants),
    totalUnits,
    cart_tab: tab,
    totalParticipants: userIds ? _.size(userIds) : undefined,
    teamId,
  };
};

export const getInviteTrackingData = (userData, campaignData) => {
  const { userId, role, subscriptionState, planCode } = userData;
  const { id: campaignId, name: campaignName } = campaignData;
  return {
    user_id: userId,
    role,
    subscription_state: subscriptionState,
    campaign_id: campaignId,
    campaign_name: campaignName,
    plan_code: planCode,
  };
};
