import React from 'react';
import styled, { CSSObject, ThemeProvider } from 'styled-components';
import breakpoints from '../../utils/breakpoints';
import useCurrentWidth from '../../hooks/useCurrentWidth';
import { getTheme } from './buttonCssProperties';
import LoadingSvg from './LoadingSVG';
import classNames from 'classnames';

export type ButtonVariation =
  | 'primary'
  | 'black'
  | 'rounded-primary'
  | 'rounded-black'
  | 'primary-outline'
  | 'black-outline';

export type ButtonType = 'submit' | 'reset' | 'button';

export interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  children: React.ReactNode;
  width?: number;
  height?: number;
  type?: ButtonType;
  variation?: ButtonVariation;
  small?: boolean;
  containerFullWidth?: boolean;
  styles?: CSSObject;
  loading?: boolean;
}

const DESKTOP_DEFAULT_WIDTH = 220;
const MOBILE_DEFAULT_WIDTH = 351;

const FFFButton = styled.button.attrs((props) => ({
  type: props.type ? props.type : 'button',
  disabled: props.disabled,
  name: props.name,
  onClick: props.onClick ? props.onClick : () => null,
}))<ButtonProps>((props) => {
  const currentWidth = useCurrentWidth();
  const isSmallDisplay = currentWidth <= breakpoints.small;

  const calculateWidth = () => {
    const smallScreenDisplayWidth = isSmallDisplay ? MOBILE_DEFAULT_WIDTH : DESKTOP_DEFAULT_WIDTH;
    const smallButtonWidth = props.small ? 105 : smallScreenDisplayWidth;
    return props.width || smallButtonWidth;
  };

  const buttonWidth = props.containerFullWidth ? '100%' : calculateWidth();

  const defaultTextTransform = props.small ? 'none' : 'uppercase';

  return {
    cursor: 'pointer',
    padding: 0,
    width: buttonWidth,
    height: props.height || 48,
    fontFamily: "'Nunito Sans', sans-serif;",
    fontWeight: 'bold',
    lineHeight: 1.33,
    letterSpacing: 1,
    fontSize: 16,
    textTransform: defaultTextTransform,
    ...props.theme,
    ...props.styles,
  };
});

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      onClick,
      width,
      height,
      variation,
      type,
      name,
      disabled,
      small,
      containerFullWidth,
      styles,
      children,
      loading,
      className,
    },
    ref
  ) => {
    return (
      <ThemeProvider theme={getTheme(variation || 'primary')}>
        <FFFButton
          onClick={onClick}
          width={width}
          height={height}
          disabled={disabled}
          small={small}
          containerFullWidth={containerFullWidth}
          styles={styles}
          type={type}
          name={name}
          ref={ref}
          className={className}
        >
          {loading ? <LoadingSvg /> : children}
        </FFFButton>
      </ThemeProvider>
    );
  }
);

export default Button;
