import React from 'react';
import { UserProvider } from '@fff-web/fff-utilities';
import ErrorBoundary from './ErrorBoundary';
import { GlobalFooterDefault } from './GlobalFooterDefault';

export const GlobalFooter = (props) => (
  <ErrorBoundary>
    <GlobalFooterDefault {...props} />
  </ErrorBoundary>
);

export default GlobalFooter;
