import React, { useState } from 'react';
import classNames from 'classnames';
import { ReactComponent as Loading } from './loading.svg';
import { ReactComponent as AddDisabledIcon } from './add-disabled.svg';
import { ReactComponent as AddIcon } from './add.svg';
import { ReactComponent as MinusDisabledIcon } from './minus-disabled.svg';
import { ReactComponent as MinusIcon } from './minus.svg';
import { ReactComponent as RemoveDisabledIcon } from './remove-disabled.svg';
import { ReactComponent as RemoveIcon } from './remove.svg';

import styles from './IncrementButton.module.css';

const getRemoveIcon = (loading, quantity) => {
  if (quantity === 1) {
    if (loading) return <RemoveDisabledIcon />;
    return <RemoveIcon />;
  }
  if (loading) return <MinusDisabledIcon />;
  return <MinusIcon />;
};

const IncrementButton = ({
  id,
  quantity,
  isVariantAtPurchaseLimit,
  onAddItem,
  soldOut,
  isOverInventoryLimit,
  onRemoveItem,
  sponsored,
}) => {
  const [loading, setLoading] = useState(false);
  const [disableAdd, setDisableAdd] = useState(false);
  const quantityDisplay = `${quantity}${isVariantAtPurchaseLimit ? ' MAX' : ''}`;
  const disabledAddButton =
    loading || isVariantAtPurchaseLimit || soldOut || isOverInventoryLimit || disableAdd;

  const handleClickAdd = async () => {
    setLoading(true);
    setDisableAdd(false);
    const response = await onAddItem('+');
    if (response && !response.success) {
      setDisableAdd(true);
    }
    setLoading(false);
  };

  const handleClickRemove = async () => {
    const cta = quantity === 1 ? 'x' : '-';
    setLoading(true);
    setDisableAdd(false);
    const response = await onRemoveItem(cta);
    if (response && !response.success) {
      setDisableAdd(true);
    }
    setLoading(false);
  };

  return (
    <div className={styles.buttons}>
      <button
        type="button"
        id={`${id}_remove_cart`}
        className={classNames('cart-button', 'remove-button', {
          'sponsored-button': sponsored,
        })}
        disabled={loading}
        onClick={handleClickRemove}
        data-dd-action-name="Decrement quantity button in the cart"
      >
        {getRemoveIcon(loading, quantity)}
      </button>
      {loading ? <Loading /> : <span className={styles.lineText}>{quantityDisplay}</span>}
      <button
        type="button"
        id={`${id}_add_cart`}
        className={classNames('cart-button', 'add-button', {
          'sponsored-button': sponsored,
        })}
        disabled={disabledAddButton}
        onClick={handleClickAdd}
        data-dd-action-name="Increment quantity button in the cart"
      >
        {disabledAddButton ? <AddDisabledIcon /> : <AddIcon />}
      </button>
    </div>
  );
};

export default IncrementButton;
