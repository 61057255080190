import { LoadSegmentProps } from '../../interfaces';
import isBrowser from './isBrowser';

export const loadSegment = ({ token }: LoadSegmentProps) => {
  if (!isBrowser()) {
    return false;
  }
  // TODO: Property 'analytics' does not exist on type 'Window & typeof globalThis'.
  // @ts-ignore eslint-disable-next-line no-multi-assign
  const analytics = (window.analytics = window.analytics || []);
  if (!analytics.initialize && !analytics.invoked) {
    analytics.invoked = !0;
    analytics.methods = [
      'trackSubmit',
      'trackClick',
      'trackLink',
      'trackForm',
      'pageview',
      'identify',
      'reset',
      'group',
      'track',
      'ready',
      'alias',
      'debug',
      'page',
      'once',
      'off',
      'on',
      'addSourceMiddleware',
      'addIntegrationMiddleware',
      'setAnonymousId',
      'addDestinationMiddleware',
    ];
    // eslint-disable-next-line func-names
    analytics.factory = function (e: any) {
      // eslint-disable-next-line func-names
      return function () {
        // eslint-disable-next-line prefer-rest-params
        const t = Array.prototype.slice.call(arguments);
        t.unshift(e);
        analytics.push(t);
        return analytics;
      };
    };
    for (let e = 0; e < analytics.methods.length; e++) {
      const key = analytics.methods[e];
      analytics[key] = analytics.factory(key);
    }
    // eslint-disable-next-line func-names
    analytics.load = function ({ key, e }: { key: string; e?: string }) {
      if (!isBrowser()) {
        return true;
      }
      const t = document.createElement('script');
      t.type = 'text/javascript';
      t.async = !0;
      t.src = `https://cdn.segment.com/analytics.js/v1/${key}/analytics.min.js`;
      const n = document.getElementsByTagName('script')[0];
      n?.parentNode?.insertBefore(t, n);
      // eslint-disable-next-line no-underscore-dangle
      analytics._loadOptions = e;
      return true;
    };
    analytics.SNIPPET_VERSION = '4.13.1';
    analytics.load({ key: token });
    return true;
  }
  return false;
};
