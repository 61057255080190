import { getEnv } from "../environment";
import request from "./request";
import { IMembership, IMembershipFlag } from "../../interfaces/IMyMembership";

const fetchAccountInfo = async (): Promise<IMembership> => {
  const response = await request({ path: getEnv().REACT_APP_MEMBERSHIP_INFO_URL });
  let isRefillSubscription = false;
  if (response?.flags && response?.membership_state && response.membership_state === 'active') {
    const hasRefillSubscription = response.flags.some((flag: IMembershipFlag) => flag.display_name === 'has_refill_subscription');
    const hasBoxSubscription = response.flags.some((flag: IMembershipFlag) => flag.display_name === 'has_box_subscription');  
    isRefillSubscription = hasRefillSubscription && !hasBoxSubscription;
  }
  return { isRefillSubscription, ...response};
};

export default fetchAccountInfo;
