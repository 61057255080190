import request from './request';

export default async function fetchUserData(env) {
  const endpoint = `api/1/accounts`;
  const headers = {
    Accept: 'application/vnd.fabfitfun.v2+json',
  };
  const response = await request(endpoint, 'GET', env, headers);
  return response;
}
