import React from 'react';

const CartContext = React.createContext({
  account: null,
  cartData: null,
  receiveUpdateCart: null,
  onClickProduct: null,
  teamId: null,
  openInviteModal: null,
  receiveTeamCarts: null,
  socialAvatars: null,
  teamCartData: null,
  receiveCartProducts: null,
  seasonalPlanPrice: null,
  saleRefreshKey: null,
});

const CartContextProvider = ({
  account,
  cartData,
  receiveUpdateCart,
  onClickProduct,
  children,
  teamId,
  openInviteModal,
  receiveTeamCarts,
  socialAvatars,
  teamCartData,
  receiveCartProducts,
  seasonalPlanPrice,
  saleRefreshKey,
}) => (
  <CartContext.Provider
    // TODO: Add useMemo here
    // eslint-disable-next-line react/jsx-no-constructed-context-values
    value={{
      account,
      cartData,
      receiveUpdateCart,
      onClickProduct,
      teamId,
      openInviteModal,
      receiveTeamCarts,
      socialAvatars,
      teamCartData,
      receiveCartProducts,
      seasonalPlanPrice,
      saleRefreshKey,
    }}
  >
    {children}
  </CartContext.Provider>
);

export const useCartContext = () => {
  const context = React.useContext(CartContext);
  return context;
};

export default CartContextProvider;
