import React, { useMemo } from 'react';
import { map, orderBy, get } from 'lodash';
import LazyLoad from 'react-lazyload';

import { CART_TYPE } from '../../../../services/utils/types';
import { updateCartHandler, trackUpdateCart } from '../../../../services/utils/update-cart';
import { clickProductHandler } from '../../../../services/utils/cart-item';
import { formatCurrency } from '../../../../services/utils/currency';

import Tooltip from '../../../../shared/components/Tooltip/Tooltip';
import CartItem from '../../CartItem/CartItem';
import { getCartItemTrackingData } from '../../../../services/utils/tracking-data';

import styles from './SocialCart.module.css';
import { useInternalContext } from '../../../../context/InternalContext';
import { useDataContext } from '../../../../context/DataContext';

const CAMPAIGN_TYPE = 'EDIT';

const SocialCart = ({
  cartData,
  variantQuantities,
  setCartData,
  userShippingRestriction,
  campaignData,
  receiveUpdateCart,
  closeCart,
  ownCartVariants,
  itemsContainerRef,
}) => {
  const { env, onClickProduct } = useInternalContext();
  const { userData } = useDataContext();
  const { type: campaignType } = campaignData;

  const { cartVariants } = cartData;
  const sorted = useMemo(
    () =>
      orderBy(
        cartVariants,
        [
          (variant) => {
            return variant.users.length;
          },
          (variant) => {
            const quantity = get(variantQuantities, variant.id, 0);
            return quantity;
          },
          (variant) => {
            return variant.soldOut;
          },
        ],
        ['desc', 'desc', 'asc']
      ),
    [cartVariants, variantQuantities]
  );

  const handleUpdateCart = (id, trackingData, operation, cta) => {
    trackUpdateCart(trackingData, cta, operation);
    return updateCartHandler(
      id,
      operation,
      CAMPAIGN_TYPE,
      [...cartVariants, ...ownCartVariants],
      receiveUpdateCart,
      null,
      env,
      setCartData
    );
  };

  const handleProductClick = (shareUrl) =>
    clickProductHandler(shareUrl, CAMPAIGN_TYPE, onClickProduct, closeCart, env);

  return (
    <div className={styles.itemsContainer} ref={itemsContainerRef}>
      {map(sorted, (variant, index) => {
        const {
          id,
          cartDisplayName,
          imageUrl,
          salePrice,
          shareUrl,
          soldOut,
          cartLimit,
          users,
          shippingRestrictions,
        } = variant;
        const quantity = get(variantQuantities, id, 0);
        const trackingData = getCartItemTrackingData(variant, index, userData, campaignData);
        const displayPrice =
          quantity > 0 && salePrice
            ? formatCurrency(salePrice * quantity)
            : formatCurrency(salePrice);

        return (
          <LazyLoad overflow height={85}>
            <CartItem
              key={id}
              id={id}
              quantity={quantity}
              cartDisplayName={cartDisplayName}
              imageUrl={imageUrl}
              displayPrice={displayPrice}
              shareUrl={shareUrl}
              soldOut={soldOut}
              cartLimit={cartLimit}
              users={users}
              shippingRestrictions={shippingRestrictions}
              userShippingRestriction={userShippingRestriction}
              onClickProduct={() => handleProductClick(shareUrl)}
              onAddItem={(cta) => handleUpdateCart(id, trackingData, 'ADD', cta)}
              onRemoveItem={(cta) => handleUpdateCart(id, trackingData, 'REMOVE', cta)}
              cartType={CART_TYPE.social}
              campaignType={campaignType}
            />
          </LazyLoad>
        );
      })}
      <Tooltip place="right" id="shipping-restriction" className={styles.tooltip} />
    </div>
  );
};

export default SocialCart;
