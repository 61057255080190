import React from 'react';
import styles from './DisabledSocialCart.module.css';

const DisabledSocialCart = () => {
  return (
    <div className={styles.container}>
      <div className={styles.text}>
        <i className="fal fa-exclamation-circle" />
        <h4>We&#39;re Having Some Issues</h4>
        <p>We are unable to get your friends&#39; carts right now. Please try again later.</p>
      </div>
    </div>
  );
};

export default DisabledSocialCart;
