import { useState, useEffect } from 'react';
import { FFFStorage, Compare } from '@fff-web/fff-utilities';
import { getSubscriptionPlans } from '../services';

const subscriptionPlansKey = 'subscription-plans';
const cacheDurationInMs = 15 * 60 * 1000; //15 minutes

const useSubscriptionPlans = () => {
  const [subscriptionPlans, setSubscriptionPlans] = useState(
    FFFStorage.getFromStorage({ key: subscriptionPlansKey }) || {}
  );

  useEffect(() => {
    const subscriptionPlansCache = FFFStorage.getFromStorage({ key: subscriptionPlans });
    if (subscriptionPlansCache) {
      if (!Compare.isEqualObject(subscriptionPlans, subscriptionPlansCache)) {
        setSubscriptionPlans(subscriptionPlansCache);
      }
    } else {
      getSubscriptionPlans().then((content) => {
        setSubscriptionPlans(content);
        FFFStorage.setToStorage({
          key: subscriptionPlans,
          value: content,
          durationInMs: cacheDurationInMs,
        });
      });
    }
  }, [subscriptionPlans]);

  return { subscriptionPlans };
};

export default useSubscriptionPlans;
