export const HOSTNAMES = {
  local: process.env.REACT_APP_HOSTNAME_LOCAL,
  dev: process.env.REACT_APP_HOSTNAME_DEV,
  load: process.env.REACT_APP_HOSTNAME_LOAD,
  load01: process.env.REACT_APP_HOSTNAME_LOAD01,
  qa: process.env.REACT_APP_HOSTNAME_QA,
  prod: process.env.REACT_APP_HOSTNAME,
};

export const ACE_API_HOSTS = {
  local: process.env.REACT_APP_ACE_API_LOCAL,
  dev: process.env.REACT_APP_ACE_API_DEV,
  load: process.env.REACT_APP_ACE_API_LOAD,
  load01: process.env.REACT_APP_ACE_API_LOAD01,
  qa: process.env.REACT_APP_ACE_API_QA,
  prod: process.env.REACT_APP_ACE_API,
};

export const AVATAR_API_HOSTS = {
  local: process.env.REACT_APP_AVATAR_API_LOCAL,
  dev: process.env.REACT_APP_AVATAR_API_DEV,
  load: process.env.REACT_APP_AVATAR_API_LOAD,
  load01: process.env.REACT_APP_AVATAR_API_LOAD01,
  qa: process.env.REACT_APP_AVATAR_API_QA,
  prod: process.env.REACT_APP_AVATAR_API,
};

export const PAYMENT_API_HOSTS = {
  local: process.env.REACT_APP_PAYMENT_API_LOCAL,
  dev: process.env.REACT_APP_PAYMENT_API_DEV,
  load: process.env.REACT_APP_PAYMENT_API_LOAD,
  load01: process.env.REACT_APP_PAYMENT_API_LOAD01,
  qa: process.env.REACT_APP_PAYMENT_API_QA,
  prod: process.env.REACT_APP_PAYMENT_API,
};
