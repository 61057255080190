import React, { useContext } from 'react';
import classNames from 'classnames';
import {
  MENU_LABELS,
  createTrackingData,
  createRoutes,
} from '../../utils/prospectGlobalNav';
import { isVariation } from '../../helpers/functions/isVariation';
import { ProspectNavContext } from '../../context/ProspectNavContext';
import styles from './ProspectBanner.module.css';
import { GROWTH_GLOBAL_NAV } from '../../utils/featureFlagVariants/growth-global-nav';

function findRouteByLabel(baseUrl, loginUrl, label) {
  return createRoutes(baseUrl, loginUrl).find((route) => route.label === label);
}

const { VARIATION_D } = GROWTH_GLOBAL_NAV;

const ProspectBanner = () => {
  const { env, segmentTrackingData, mainProps } = useContext(ProspectNavContext);
  const baseUrl = env.REACT_APP_BASE_URL;
  const loginUrl = env.REACT_APP_LOGIN_BASE_URL;

  const memberOption = findRouteByLabel(baseUrl, loginUrl, MENU_LABELS.membership);
  const shopOption = findRouteByLabel(baseUrl, loginUrl, MENU_LABELS.shop);
  const { variationKey } = mainProps;

  const isVariationD = isVariation(variationKey, VARIATION_D);

  const bannerClass = classNames(styles.banner, {
    [styles.bannerVariationD]: isVariationD,
  });

  const handleCTAOnClick = (buttonType, url) => {
    createTrackingData({
      ctaName: buttonType,
      segmentTrackingData,
    });
    window.location.href = url;
  };

  return (
    <div data-testid="header-banner" className={bannerClass}>
      <ul>
        <li className={styles.bannerItem}>
          <button type="button" onClick={() => handleCTAOnClick(MENU_LABELS.membership, memberOption.url)}>
            {memberOption.label}
          </button>
        </li>
        {
          !isVariationD && (
            <li className={styles.bannerItem}>
              <button type="button" onClick={() => handleCTAOnClick(MENU_LABELS.shop, shopOption.url)}>
                {shopOption.label}
              </button>
            </li>
          )
        }
      </ul>
    </div>
  );
};

export default ProspectBanner;
