import request from './request';
import { getEnv } from '../environment';
const env = getEnv();
const {
  REACT_APP_QUESTIONNAIRE_API_URL: questionnaireApi,
  REACT_APP_USER_URL: userApi,
  REACT_APP_GROWTH_URL: growthApi,
} = env;

interface QuestionType {
  question: {
    id: string;
  };
  choices: Array<{
    id: string;
  }>;
}
interface AnswersType {
  countryIsoCode: string;
  answers: Array<QuestionType>;
}

export const getQuestionnaire = (variant: string) => {
  return request({
    path: `${questionnaireApi}/public/questionnaires?name=guided-quiz&variation=${variant}`,
  });
};

export const createUser = (email: string) => {
  return request({
    path: `${userApi}/leads/create`,
    method: 'POST',
    body: JSON.stringify({
      email,
    }),
  });
};

export const postQuestionnaire = (sessionId: string, answers: AnswersType) => {
  return request({
    path: `${questionnaireApi}/public/answers/sessions/${sessionId}`,
    method: 'POST',
    body: JSON.stringify(answers),
  });
};

export const getCountryIsoCode = () => {
  return request({
    path: `${growthApi}/location/country-by-ip`,
  });
};
