import { useState, useEffect } from 'react';
import * as LDClient from 'launchdarkly-js-client-sdk';
import { getLDClientId } from '../utils/getLdClientId';

export function useBootstrapLD(env, userId) {
  const [downloadMobileAppWebBannerFF, setDownloadMobileAppWebBannerFF] = useState(null);
  const [downloadMobileAppWebBannerReady, setDownloadMobileAppWebBannerReady] = useState(false);
  const [ordersCtaFF, setOrdersCtaFF] = useState(null);
  const [ordersCtaReady, setOrdersCtaReady] = useState(false);
  const [webRatingsAndReviewInGlobalNavDropDownFF, setWebRatingsAndReviewInGlobalNavDropDownFF] = useState(null);
  const [webRatingsAndReviewInGlobalNavDropDownReady, setWebRatingsAndReviewInGlobalNavDropDownReady] = useState(false);
  const [ccToastNotificationFF, setCcToastNotificationFF] = useState(null);
  const [ccToastNotificationReady, setCcToastNotificationReady] = useState(false);
  const [newMemberOnboardingFF, setNewMemberOnboardingFF] = useState(null);
  const [newMemberOnboardingReady, setNewMemberOnboardingReady] = useState(false);

  const clientId = getLDClientId(env);

  useEffect(() => {
    const ldclient = LDClient.initialize(clientId, {
      key: userId || 'anonymous',
    });
    ldclient.on('ready', () => {
      const downloadMobileAppWebBanner = ldclient.variation(
        'download-mobile-app-web-banner',
        false,
      );
      setDownloadMobileAppWebBannerFF(downloadMobileAppWebBanner);
      setDownloadMobileAppWebBannerReady(true);

      const ordersCta = ldclient.variation('orders-cta', false);
      setOrdersCtaFF(ordersCta);
      setOrdersCtaReady(true);

      const webRatingsAndReviewInGlobalNavDropDown = ldclient.variation(
        'web-ratings-and-review-in-global-nav-drop-down',
        false,
      );
      setWebRatingsAndReviewInGlobalNavDropDownFF(
        webRatingsAndReviewInGlobalNavDropDown,
      );
      setWebRatingsAndReviewInGlobalNavDropDownReady(true);

      const ccToastNotification = ldclient.variation(
        'cc-toast-notification',
        false,
      );
      setCcToastNotificationFF(ccToastNotification);
      setCcToastNotificationReady(true);

      const newMemberOnboarding = ldclient.variation(
        'new-member-onboarding',
        false,
      );
      setNewMemberOnboardingFF(newMemberOnboarding);
      setNewMemberOnboardingReady(true);
    });

    ldclient.on('change:download-mobile-app-web-banner', (value) => {
      setDownloadMobileAppWebBannerFF(value);
    });
    ldclient.on('change:orders-cta', (value) => {
      setOrdersCtaFF(value);
    });
    ldclient.on(
      'change:web-ratings-and-review-in-global-nav-drop-down',
      (value) => {
        setWebRatingsAndReviewInGlobalNavDropDownFF(value);
      },
    );
    ldclient.on('cc-toast-notification', (value) => {
      setCcToastNotificationFF(value);
    });
    ldclient.on('change:new-member-onboarding', (value) => {
      setNewMemberOnboardingFF(value);
    });
  }, [userId, clientId]);

  return {
    downloadMobileAppWebBannerFF,
    downloadMobileAppWebBannerReady,
    ordersCtaFF,
    ordersCtaReady,
    webRatingsAndReviewInGlobalNavDropDownFF,
    webRatingsAndReviewInGlobalNavDropDownReady,
    ccToastNotificationFF,
    ccToastNotificationReady,
    newMemberOnboardingFF,
    newMemberOnboardingReady,
  };
}

export default useBootstrapLD;
