import React, { useState, useEffect, useMemo, useRef } from 'react';
import _ from 'lodash';
import '@fff-web/fff-ui-shared/FontAwesome';
import ErrorBoundary from '../../shared/components/ErrorBoundary/ErrorBoundary';
import fetchUserData from '../../services/apis/userRequests';
import { fetchCampaignsData } from '../../services/apis/campaignRequests';
import {
  fetchTeamCarts,
  fetchTeamId,
  fetchSocialCredit,
  fetchTeamAvatars,
} from '../../services/apis/socialRequests';
import { fetchCart, getPurchasedVariants } from '../../services/apis/cartRequests';
import { fetchCredits } from '../../services/apis/paymentRequests';

import DataContextProvider from '../../context/DataContext';
import { useCartContext } from '../../context/CartContext';
import { useInternalContext } from '../../context/InternalContext';
import useAnalytics from '../../shared/hooks/useAnalytics';
import { useBootstrapLD } from '../../shared/hooks/useBootstrapLD';

import CartOverlay from './CartOverlay/CartOverlay';
import CartButton from './CartButton/CartButton';
import CartContent from './CartContent/CartContent';
import NewItemAddedModal from './NewItemAddedModal/NewItemAddedModal';

import '../../index.module.css';
import styles from './CartContainer.module.css';

import {
  SALE_TYPES,
  getActiveCampaign,
  campaignIsLive,
  getCampaignType,
} from '../../services/utils/campaign';
import { CartOnboarding, NEW_MEMBER_STATE } from './CartOnboarding/CartOnboarding';

const INITIAL_CREDITS = {
  purchaseCredit: 0,
  season: {
    credit: 0,
  },
  edit: {
    credit: 0,
  },
};

const CartContainer = ({ newMemberState, onNewMemberStateFetched }) => {
  const {
    account,
    cartData,
    receiveUpdateCart,
    onClickProduct,
    teamId: teamIdFromPandora,
    openInviteModal,
    receiveTeamCarts,
    socialAvatars,
    teamCartData,
    receiveCartProducts,
    seasonalPlanPrice,
    saleRefreshKey,
  } = useCartContext();
  const { page, isEditPage, isAddOnsPage, env, isShopPage, isBoostsPage } = useInternalContext();
  const [isOpen, setIsOpen] = useState(false);
  const [campaignData, setCampaignData] = useState(null);
  const [shopCartDataState, setShopCartDataState] = useState(null);

  const [saleCartDataState, setSaleCartDataState] = useState(null);
  const [userData, setUserData] = useState(account || {});
  const [userDataLoading, setUserDataLoading] = useState(true);
  const [initialCredits, setInitialCredits] = useState(INITIAL_CREDITS);
  const [teamCarts, setTeamCarts] = useState({
    cartVariants: [],
    userIds: [],
  });
  const [teamCartsLoading, setTeamCartsLoading] = useState(false);
  const [teamAvatars, setTeamAvatars] = useState([]);
  const [teamAvatarsLoading, setTeamAvatarsLoading] = useState(true);
  const [socialCreditData, setSocialCreditData] = useState({
    creditName: '',
    socialCredit: 0,
  });
  const [teamId, setTeamId] = useState(null);
  //eslint-disable-next-line
  const [error, setError] = useState(null);

  const isPublicShopping = !account?.loggedIn && isShopPage;

  const activeCampaign = useMemo(
    () =>
      isPublicShopping
        ? campaignData
        : getActiveCampaign(
            campaignData && campaignData.season,
            campaignData && campaignData.edit,
            isAddOnsPage,
            isBoostsPage,
            isEditPage
          ),
    [campaignData]
  );

  const usePrevItemCount = (curItemCount) => {
    const ref = useRef();
    useEffect(() => {
      ref.current = curItemCount;
    });
    return ref.current;
  };
  const itemsCount =
    activeCampaign && !isShopPage
      ? _.get(saleCartDataState, 'itemsCount')
      : _.get(shopCartDataState, 'itemsCount');

  const {
    socialEcommFF,
    socialEcommFlagReady,
    disableTeamCartsAndAvatarsFF,
    disableTeamCartsAndAvatarsFlagReady,
    disableAvatarsFF,
    disableAvatarsReady,
    freeTierShopAccessFF,
    freeTierShopAccessReady,
    shoppingCreditFF,
    shoppingCreditReady,
    alternateSubscriptionsReady,
    selfCheckoutFF,
    selfCheckoutReady,
    newMemberOnboardingFF,
    newMemberOnboardingReady,
  } = useBootstrapLD(env, _.get(userData, 'userId'), userDataLoading);
  // Use prevItemCount to determine when to update social discount, specifically:
  // 1. every time the user clicks on the cart icon (first tab) given valid teamId/FF.
  // 2. every time the user's cart size changes from 0 to non-zero.
  const prevItemCount = isOpen ? usePrevItemCount(itemsCount) : usePrevItemCount(0); // reset prevItemCount to 0 when cart closes to ensure social discount is updated every time cart opens.
  const userSocialAccess = _.get(activeCampaign, 'userSocialAccess', false);

  const displaySocialCart =
    socialEcommFF &&
    userSocialAccess &&
    !isShopPage &&
    campaignIsLive(activeCampaign?.campaignStatus);

  const handleCloseCart = () => setIsOpen(false);
  const toggleCart = () => setIsOpen((prevState) => !prevState);

  useEffect(() => {
    setTeamId(teamIdFromPandora);
  }, [teamIdFromPandora]);

  useEffect(() => {
    if (socialAvatars) {
      setTeamAvatars(socialAvatars);
      setTeamAvatarsLoading(false);
    }
  }, [socialAvatars]);

  useEffect(() => {
    if (displaySocialCart && isEditPage && teamId) {
      setTeamCartsLoading(true);
      if (teamCartData) {
        setTeamCarts(teamCartData);
        setTeamCartsLoading(false);
      }
    }
  }, [teamCartData, displaySocialCart, isEditPage, teamId]);

  useEffect(() => {
    const getTeamId = async () => {
      const id = _.get(activeCampaign, 'id', '');
      const campaignWindow = _.get(activeCampaign, 'campaignWindow', '');
      if (id) {
        const response = await fetchTeamId(env, id, campaignWindow);
        const responseTeamId = _.get(response, 'data.items[0].teamId');
        setTeamId(responseTeamId);
      }
    };
    if (!isEditPage && displaySocialCart) {
      getTeamId();
    }
  }, [activeCampaign, displaySocialCart, isEditPage]);

  // get team carts data when user open global cart
  useEffect(() => {
    const getTeamAvatars = async (userIds) => {
      if (_.size(userIds)) {
        setTeamAvatarsLoading(true);
        const avatarResponse = await fetchTeamAvatars(userIds, env);
        setTeamAvatars(avatarResponse);
        setTeamAvatarsLoading(false);
      }
    };
    const getTeamCartsData = async () => {
      setTeamCartsLoading(true);
      try {
        const cartResponse = await fetchTeamCarts(env, teamId, receiveCartProducts);
        const { userIds, cartProducts, cartVariants } = cartResponse;
        if (!disableAvatarsFF) {
          getTeamAvatars(userIds);
        }
        if (receiveTeamCarts) {
          receiveTeamCarts(cartProducts, userIds, cartVariants, socialEcommFF, disableAvatarsFF);
        } else {
          setTeamCarts(cartResponse);
        }
        setTeamCartsLoading(false);
      } catch (err) {
        setError(err);
        setTeamCartsLoading(false);
      }
    };
    if (isOpen && teamId && socialEcommFF && !disableTeamCartsAndAvatarsFF) {
      getTeamCartsData(teamId);
    }
  }, [isOpen, teamId, socialEcommFF, disableTeamCartsAndAvatarsFF]);

  useEffect(() => {
    const getSocialCreditData = async () => {
      try {
        const response = await fetchSocialCredit(env, teamId);
        setSocialCreditData(response);
      } catch (err) {
        setError(err);
      }
    };
    if (
      isOpen &&
      teamId &&
      socialEcommFF &&
      !disableTeamCartsAndAvatarsFF &&
      itemsCount > 0 &&
      prevItemCount === 0 &&
      !isPublicShopping
    ) {
      getSocialCreditData();
    }
  }, [isOpen, teamId, socialEcommFF, disableTeamCartsAndAvatarsFF, itemsCount, prevItemCount]);

  useAnalytics(env);

  // get campaign data and initial credits
  useEffect(() => {
    const getUserData = async () => {
      const response = await fetchUserData(env);
      setUserData(response);
      setUserDataLoading(false);
    };
    const getCampaignData = async () => {
      try {
        const campaignType = getCampaignType(page);
        const campaigns = await fetchCampaignsData(campaignType, env, isPublicShopping);
        setCampaignData(campaigns);
      } catch (err) {
        setError(err);
      }
    };
    const getInitialCredits = async () => {
      try {
        const credits = await fetchCredits(env);
        setInitialCredits(credits);
      } catch (err) {
        setError(err);
      }
    };
    getCampaignData();
    if (!isPublicShopping) {
      if (!account) getUserData();
      getInitialCredits();
    }
  }, [isPublicShopping]);
  // get sale cart data (add-ons or edit campaign)
  useEffect(() => {
    // return live sale based on userAccess of activeCampaign
    const getLiveSale = () => {
      if (activeCampaign?.userAccess) return activeCampaign.type;
      return null;
    };
    const getSaleCartData = async () => {
      try {
        const liveSale = getLiveSale();
        if (liveSale) {
          const saleCart = await fetchCart(liveSale, isEditPage || isAddOnsPage, env);
          const { cartDetails, count, cartId, customizeCampaignDetails } = saleCart;
          let { cartVariants } = saleCart;
          const { role } = userData;
          const { campaignWindow, id, type } = campaignData[_.lowerCase(liveSale)];
          if (campaignWindow === 2 && role === 'SELECT') {
            cartVariants = await getPurchasedVariants(id, type, cartVariants, env);
          }
          setSaleCartDataState({
            cartVariants,
            cartDetails,
            itemsCount: count,
            cartId,
            customizeCampaignDetails,
          });
        } else {
          // added below lines for loading state. Avoid to be null if sale page is closed
          setSaleCartDataState({
            cartVariants: [],
            cartDetails: {},
            itemsCount: 0,
            cartId: null,
            customizeCampaignDetails: null,
          });
        }
      } catch (err) {
        setError(err);
      }
    };
    if (campaignData && !isPublicShopping) getSaleCartData();
  }, [campaignData, userData, saleRefreshKey]);

  // get shop cart data if non social campaign
  useEffect(() => {
    const getShopCartData = async () => {
      try {
        const shopCart = await fetchCart(SALE_TYPES.SHOP, isShopPage, env, isPublicShopping);
        const { cartDetails, cartVariants, count, cartId } = shopCart;
        setShopCartDataState({
          cartVariants,
          cartDetails,
          itemsCount: count,
          cartId,
        });
      } catch (err) {
        // added to avoid being null if shop is "closed" - user expired
        // without this the cart will be stuck in loading state
        setShopCartDataState({
          cartVariants: [],
          cartDetails: {},
          itemsCount: 0,
          cartId: null,
        });
        setError(err);
      }
    };
    if (!displaySocialCart) getShopCartData();
  }, [displaySocialCart, isPublicShopping]);

  useEffect(() => {
    if (cartData) {
      if (isShopPage) setShopCartDataState(cartData);
      else setSaleCartDataState(cartData);
    }
  }, [cartData]);

  useEffect(() => {
    if (account) {
      setUserData(account);
      setUserDataLoading(false);
    }
  }, [account, isPublicShopping]);

  const isNewMember = !!newMemberState;
  const isNewMemberFlow = isNewMember && Object.values(NEW_MEMBER_STATE).includes(newMemberState);

  const isLoading = isPublicShopping
    ? !shopCartDataState
    : !campaignData ||
      (!displaySocialCart && !shopCartDataState) ||
      !saleCartDataState ||
      !socialEcommFlagReady ||
      !disableTeamCartsAndAvatarsFlagReady ||
      !disableAvatarsReady ||
      !freeTierShopAccessReady ||
      !shoppingCreditReady ||
      !alternateSubscriptionsReady ||
      !selfCheckoutReady;

  const isNewMemberOnboarding = newMemberOnboardingFF && newMemberOnboardingReady && isNewMember;

  return (
    <ErrorBoundary>
      <DataContextProvider
        userData={userData}
        initialCredits={initialCredits}
        socialCreditData={socialCreditData}
        teamId={teamId}
        teamAvatars={teamAvatars}
        teamAvatarsLoading={teamAvatarsLoading}
        socialEcommFF={socialEcommFF}
        disableTeamCartsAndAvatarsFF={disableTeamCartsAndAvatarsFF}
        disableAvatarsFF={disableAvatarsFF}
        onClickProduct={onClickProduct}
        openInviteModal={openInviteModal}
        shoppingCreditFF={shoppingCreditFF}
        isNewMemberOnboarding={isNewMemberOnboarding}
        isNewMemberFlow={isNewMemberFlow}
        newMemberState={newMemberState}
      >
        {isOpen && <CartOverlay closeCart={handleCloseCart} />}
        <div className={styles.container}>
          {isPublicShopping && shopCartDataState && (
            <NewItemAddedModal
              cartData={shopCartDataState}
              handleOpenCart={() => setIsOpen(true)}
            />
          )}
          <CartButton itemsCount={itemsCount} toggleCart={toggleCart} />
          {isOpen && (
            <CartContent
              activeCampaign={activeCampaign}
              shopCampaignData={campaignData?.shop}
              teamCarts={teamCarts}
              teamCartsLoading={teamCartsLoading}
              displaySocialCart={displaySocialCart}
              isLoading={isLoading}
              saleCartDataState={saleCartDataState}
              setSaleCartDataState={setSaleCartDataState}
              shopCartDataState={shopCartDataState}
              setShopCartDataState={setShopCartDataState}
              closeCart={handleCloseCart}
              receiveUpdateCart={receiveUpdateCart}
              receiveCartProducts={receiveCartProducts}
              freeTierShopAccessFF={freeTierShopAccessFF}
              isPublicShopping={isPublicShopping}
              selfCheckoutFF={selfCheckoutFF}
              itemsCount={itemsCount}
              seasonalPlanPrice={seasonalPlanPrice}
            />
          )}
        </div>
      </DataContextProvider>
      <CartOnboarding
        newMemberOnboardingFF={newMemberOnboardingFF}
        campaignData={campaignData}
        userId={userData?.userId}
        saleItemsCount={saleCartDataState?.itemsCount}
        isNewMember={isNewMember}
        newMemberState={newMemberState}
        onNewMemberStateFetched={onNewMemberStateFetched}
      />
    </ErrorBoundary>
  );
};

export default CartContainer;
