import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Avatar from '../Avatar';
import { toLowerCase } from '../../helpers/string';
import { getMembershipLabelGlobalNav } from '@fff-web/fff-utilities';

const UserProfileItem = ({
  userInfo: {
    firstName,
    lastName,
    avatar,
    role,
    planCode
  },
  membershipInfo,
  isDesktop,
}) => (
  <div className="user-profile__item">
    {!isDesktop && <Avatar image={avatar} />}
    <div className="user-profile__item__detail">
      <div className="user-profile__item__detail__name">
        {firstName} {lastName}
        {membershipInfo?.membership_state && (
          <span
            className={classNames(
              'subscription-state',
              toLowerCase(membershipInfo.membership_state),
            )}
          >
            {membershipInfo.membership_state}
          </span>
        )}
      </div>
      <div className="user-profile__item__detail__subscription">
        <span>{getMembershipLabelGlobalNav({ membershipInfo, role, planCode })}</span>
      </div>
    </div>
  </div>
);

UserProfileItem.defaultProps = {
  userInfo: {
    firstName: '',
    lastName: '',
    role: '',
    subscriptionState: '',
    subscriptionType: '',
    avatar: null,
  },
  isDesktop: false,
};

UserProfileItem.propTypes = {
  userInfo: PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    role: PropTypes.string,
    subscriptionState: PropTypes.string,
    subscriptionType: PropTypes.string,
    avatar: PropTypes.string,
  }),
  isDesktop: PropTypes.bool,
  membershipInfo: PropTypes.shape({
    membership_state: PropTypes.string,
  }),
};

export default UserProfileItem;
