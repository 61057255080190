import get from 'lodash/get';
import {
  flattenProducts,
  getSocialCartVariants,
  getSocialCartProducts,
} from '../utils/formatResponse';
import requestJson from './requestJson';
import { ACE_API_HOSTS, AVATAR_API_HOSTS } from '../utils/env';

export const fetchTeamCarts = async (env, teamId, receiveCartProducts) => {
  const host = get(ACE_API_HOSTS, env);
  const endpoint = `/1/carts/teams/${teamId}`;
  const response = await requestJson({
    url: host + endpoint,
    method: 'GET',
    env,
  });
  const {
    products: productsWithVariants,
    variants: variantsUsers,
    userIds,
    variantIdProductMap,
  } = response.data.items[0];
  const cartProducts = getSocialCartProducts(variantIdProductMap, variantsUsers);
  const { products, variants } = flattenProducts(productsWithVariants);
  if (receiveCartProducts) receiveCartProducts({ products, variants });
  const cartVariants = getSocialCartVariants(variantsUsers, variants, products);
  return { cartVariants, userIds, cartProducts };
};

export const fetchTeamId = (env, campaignId, campaignWindow) => {
  const host = get(ACE_API_HOSTS, env);
  const headers = { Accept: '*' };
  const endpoint = `/1/teams/campaigns/${campaignId}/windows/${campaignWindow}`;
  return requestJson({
    url: host + endpoint,
    method: 'GET',
    env,
    headers,
  });
};

export const fetchSocialCredit = async (env, teamId) => {
  const host = get(ACE_API_HOSTS, env);
  const endpoint = `/1/credits/teams/${teamId}`;
  const response = await requestJson({
    url: host + endpoint,
    method: 'GET',
    env,
  });
  const { amount: socialCredit, creditName } = response.data.items[0];
  return { creditName, socialCredit };
};

export const fetchTeamAvatars = (userIds, env) => {
  const host = get(AVATAR_API_HOSTS, env);
  const endpoint = `/shop-users/avatars-resolver`;
  const data = JSON.stringify({
    shopUserIds: userIds,
    withoutImageSize: false,
  });
  return requestJson({
    url: host + endpoint,
    method: 'POST',
    env,
    headers: { 'Content-Type': 'application/json' },
    body: data,
  });
};
