import React, { useState } from 'react';
import ProspectHeaderContainer from './ProspectHeaderContainer';

const ProspectHeader = () => {
  const [isOpenMenu, setIsOpenMenu] = useState(false);

  const toggleMenu = () => setIsOpenMenu(!isOpenMenu);

  return (
    <ProspectHeaderContainer
      toggleMenu={toggleMenu}
      isOpenMenu={isOpenMenu}
    />
  );
};

export default ProspectHeader;
