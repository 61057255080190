import { useState, useEffect, useContext, useCallback, useMemo } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { trackEvent } from '@fff-web/fff-analytics';
import { getBrowserType } from '@fff-web/fff-utilities';
import { showWarningToast } from '@fff-web/fff-ui-shared/Toast';
import { getTimestamp } from '../helpers/string';
import {
  getToastNotification,
  snoozeToastNotification,
} from '../services/notifications';
import GlobalNavInternalContext from '../context/GlobalNavInternalContext';

export const NOTIFICATION_CONSTANTS = {
  notificationKey: 'FFF_notification-id',
  ccStatus: 'expired',
  trackName: 'Expired CC Toast Viewed',
  trackNameViewed: 'CC Toast Viewed',
  ctaNameNavToast: 'CC NavToast View',
  trackNameSnooze: 'CC NavToast Snooze',
  trackNamePaymentUpdate: 'Cc NavToast Update Payment',
  notificationLabel: 'Billing Information',
  errorMessage: 'Error fetching toast notification',
  snoozeAttempt: 1,
  homePageLocation: 'homepage',
};

export const clearToastUId = () => {
  window.localStorage.removeItem(NOTIFICATION_CONSTANTS.notificationKey);
};

export const getLocation = () => {
  if (typeof window === 'undefined' || !window.location) {
    return '';
  }

  const { pathname } = window.location;
  if (pathname === '/') {
    return NOTIFICATION_CONSTANTS.homePageLocation;
  }

  const formatLocation = (path) => path?.slice(1).replace(/\//g, '.');
  return formatLocation(pathname);
};

const useExpirationToast = () => {
  const [isToastOpen, setIsToastOpen] = useState(false);
  const [accumulateSnoozeAttempt, setAccumulateSnoozeAttempt] = useState(0);
  const { env, flags } = useContext(GlobalNavInternalContext);
  const { ccToastNotificationFF: ccToastNotification } = flags;

  const onCloseToast = () => {
    setIsToastOpen(false);
  };

  const getOrGenerateUID = useCallback(() => {
    if (typeof window !== 'undefined') {
      let uid = window.localStorage.getItem(
        NOTIFICATION_CONSTANTS.notificationKey,
      );

      if (!uid) {
        uid = uuidv4();
        window.localStorage.setItem(
          NOTIFICATION_CONSTANTS.notificationKey,
          uid,
        );
      }

      return uid;
    }
    return '';
  }, []);

  const uid = getOrGenerateUID();
  const urlLocation = getLocation();
  const timestamp = getTimestamp();
  const browser = getBrowserType();

  const trackEventProps = useMemo(
    () => ({
      trackName: NOTIFICATION_CONSTANTS.trackName,
      ctaName: NOTIFICATION_CONSTANTS.ctaNameNavToast,
      properties: {
        cta_name: NOTIFICATION_CONSTANTS.ctaNameNavToast,
        cc_status: NOTIFICATION_CONSTANTS.ccStatus,
        url: window.location.pathname,
        timestamp,
        browser,
      },
    }),
    [],
  );

  const ToastContentOnClick = useCallback(() => {
    trackEvent({
      ...trackEventProps,
      properties: {
        ...trackEventProps.properties,
        cta_name: NOTIFICATION_CONSTANTS.trackNameViewed,
      },
    });
  }, [trackEventProps]);

  const fetchToastNotification = async () => {
    try {
      const data = await getToastNotification(env, urlLocation, uid);
      if (!data) {
        return;
      }
      trackEvent({ ...trackEventProps });
      setAccumulateSnoozeAttempt((preValue) => preValue + data.snoozeCount);
      const isSilenced = data.silenced;
      setIsToastOpen(!isSilenced);
      if (!isSilenced) {
        showWarningToast(
          NOTIFICATION_CONSTANTS.notificationLabel,
          data.htmlData.content,
          {
            onClick: ToastContentOnClick,
          },
        );
      }
    } catch (error) {
      console.error(NOTIFICATION_CONSTANTS.errorMessage, error);
      setIsToastOpen(false);
    }
  };

  useEffect(() => {
    if (ccToastNotification) {
      fetchToastNotification();
    }
  }, [ccToastNotification]);

  const onSnoozeToast = useCallback(async () => {
    if (ccToastNotification) {
      try {
        const snoozed = await snoozeToastNotification(env, urlLocation, uid);
        if (snoozed) {
          onCloseToast();
          const updatedTrackEventProps = {
            ...trackEventProps,
            properties: {
              ...trackEventProps.properties,
              cta_name: NOTIFICATION_CONSTANTS.trackNameSnooze,
              snooze_attempt: accumulateSnoozeAttempt,
            },
          };
          trackEvent(updatedTrackEventProps);
        }
      } catch (error) {
        console.error(NOTIFICATION_CONSTANTS.errorMessage, error);
      }
    }
  }, [ccToastNotification]);

  return {
    isToastOpen,
    closeToast: onCloseToast,
    snoozeToast: onSnoozeToast,
    clearToastUId,
  };
};

export default useExpirationToast;
