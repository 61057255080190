export const buildUserInfoRequest = (userInfo) => {
  if (userInfo) {
    const {
      user = {},
      avatar = null,
      loggedIn = false,
      lives: newLives = [],
    } = userInfo;
    return {
      isLoggedIn: loggedIn,
      userInfo: { ...user, avatar },
      lives: newLives,
    };
  }
  return { isLoggedIn: false, userInfo: {}, lives: [] };
};
