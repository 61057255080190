/* eslint-disable no-bitwise */
import Cookies from 'js-cookie';
import UAParser from 'ua-parser-js';
import { getEnv } from './environment';
import { GetGenericTrackingDataProps } from '../../interfaces';
import SESSION_INFO_KEY from './sessionInfoKey';

const getGenericTrackingData = (): GetGenericTrackingDataProps => {
  const sessionInfo = Cookies.getJSON(SESSION_INFO_KEY);
  if (!sessionInfo) {
    const sessionId = getUuid();
    const sessionCreationDate = new Date();
    Cookies.set(
      SESSION_INFO_KEY,
      { sessionId, sessionCreationDate },
      {
        domain: getEnv().originUrl,
        path: '/',
        expires: 1 / 24 / 2, //30 minutes
      }
    );
    return {
      device: getDevice(),
      sessionId,
      sessionCreationDate,
    };
  }

  return {
    device: getDevice(),
    sessionId: sessionInfo.sessionId,
    sessionCreationDate: sessionInfo.sessionCreationDate,
  };
};

const getDevice = (): string | undefined => {
  const deviceRegex = [
    [/(Intel Mac OS|PPC Mac OS)/i],
    [
      [UAParser.DEVICE.VENDOR, 'Apple'],
      [UAParser.DEVICE.TYPE, 'Desktop'],
    ],
    [/(Windows NT|Linux i686)/i],
    [[UAParser.DEVICE.TYPE, 'Desktop']],
    [/(Linux armv7l|Mobile)/i],
    [[UAParser.DEVICE.TYPE, 'Mobile Web']],
    [/(Tablet|iPad)/i],
    [[UAParser.DEVICE.TYPE, 'Tablet Web']],
  ];
  const parser = new UAParser(undefined, { device: deviceRegex });
  const { type } = parser.getResult().device;
  return type;
};

// Matches legacy session IDs
const getUuid = (): string => {
  let d = new Date().getTime();
  if (typeof performance !== 'undefined' && typeof performance.now === 'function') {
    d += performance.now(); // use high-precision timer if available
  }
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
    const r = (d + Math.random() * 16) % 16 | 0;
    d = Math.floor(d / 16);
    return (c === 'x' ? r : (r & 0x3) | 0x8).toString(16);
  });
};

export { getGenericTrackingData, getDevice, getUuid };
