import { TouchEvent, useCallback, useState } from 'react';

interface SwipeInput {
  onSwipedDown?: (e: TouchEvent) => void;
  onSwipedUp?: (e: TouchEvent) => void;
  onSwipeCanceled?: (e: TouchEvent) => void;
}

interface SwipeOutput {
  eventListeners: {
    onTouchStart: (e: TouchEvent) => void;
    onTouchMove: (e: TouchEvent) => void;
    onTouchEnd: (e: TouchEvent) => void;
  }
  distance: number;
  reset: () => void;
}

const useSwipe = (input: SwipeInput): SwipeOutput => {
  const [touchStart, setTouchStart] = useState(0);
  const [touchEnd, setTouchEnd] = useState(0);

  const minSwipeDistance = 50;
  const distance = touchStart - touchEnd;

  const onTouchStart = useCallback((e: TouchEvent) => {
    setTouchEnd(0); // otherwise the swipe is fired even with usual touch events
    setTouchStart(e.targetTouches[0].clientY);
  }, []);

  const onTouchMove = useCallback((e: TouchEvent) => {
    setTouchEnd(e.targetTouches[0].clientY);
  }, []);

  const onTouchEnd = (e: TouchEvent) => {
    const isUpSwipe = distance > minSwipeDistance;
    const isDownSwipe = distance < -minSwipeDistance;
    if (isDownSwipe) {
      input.onSwipedDown?.(e);
    } else if (isUpSwipe) {
      input.onSwipedUp?.(e);
    } else {
      input.onSwipeCanceled?.(e);
    }
  };

  const reset = useCallback(() => {
    setTouchStart(0);
    setTouchEnd(0);
  }, []);

  return {
    eventListeners: {
      onTouchStart,
      onTouchMove,
      onTouchEnd,
    },
    distance,
    reset,
  };
};

export default useSwipe;
