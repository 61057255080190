import React from 'react';
import get from 'lodash/get';
import EmptyState from '../EmptyState/EmptyState';
import { useInternalContext } from '../../../context/InternalContext';
import { useDataContext } from '../../../context/DataContext';

const CAMPAIGN = {
  SEASON: {
    name: 'Add-Ons',
    page: 'add-ons',
    title: 'Add-Ons',
  },
  EDIT: {
    name: 'the Edit Sale',
    page: 'edit',
    title: 'The Edit Sale',
  },
};

const ClosedSale = ({
  campaignStatus,
  campaignType,
  isOnOwnPage,
  closedForSocialUser,
  closeCart,
}) => {
  const { hostname, page } = useInternalContext();
  const {
    userData: { subscriptionState, role },
  } = useDataContext();

  const isExpiredOrCancelledWithZeroBoxes =
    subscriptionState?.toLowerCase() === 'expired' ||
    subscriptionState?.toLowerCase() === 'cancelled_with_zero_boxes';
  const userRole = isExpiredOrCancelledWithZeroBoxes
    ? subscriptionState?.toLowerCase()
    : role?.toLowerCase();

  const { page: campaignPage, name: campaignName, title: campaignTitle } = CAMPAIGN[campaignType];
  const copy = {
    select: {
      before_start: {
        icon: 'fal fa-box',
        title: `${campaignTitle} Will Open Soon`,
        description: `Find out more about ${campaignName}.`,
        cta: 'Learn More',
        link: campaignPage,
      },
      mid: {
        icon: 'fal fa-box',
        title: `${campaignTitle} is Closed`,
        description: `Find out more about ${campaignName}.`,
        cta: 'Learn More',
        link: campaignPage,
      },
    },
    customer: {
      before_start: {
        icon: 'fal fa-box',
        title: `${campaignTitle} Will Open Soon`,
        description: `Want early access to the sale?`,
        cta: 'Upgrade to Select',
        link: campaignPage,
      },
      window_one: {
        icon: 'fal fa-box-open',
        title: `${campaignTitle} is Open for Select!`,
        description: `Want to start shopping now?`,
        cta: 'Upgrade to Select',
        link: campaignPage,
      },
      mid: {
        icon: 'fal fa-box',
        title: `${campaignTitle} All Access Will Open Soon`,
        description: `Find out more about ${campaignName}.`,
        cta: 'Learn More',
        link: campaignPage,
      },
    },
    trial: {
      before_start: {
        icon: 'fal fa-box',
        title: `${campaignTitle} Will Open Soon`,
        description: `Find out more about ${campaignName}.`,
        cta: 'Learn More',
        link: campaignPage,
      },
      mid: {
        icon: 'fal fa-box',
        title: `${campaignTitle} All Access Will Open Soon`,
        description: `Find out more about ${campaignName}.`,
        cta: 'Learn More',
        link: campaignPage,
      },
    },
    expired: {
      before_start: {
        icon: 'fal fa-box',
        title: `${campaignTitle} Will Open Soon`,
        description: `Want to shop ${campaignName}?`,
        cta: 'Reactivate Now',
        link: 'reactivate-my-membership',
      },
      window_one: {
        icon: 'fal fa-box-open',
        title: `${campaignTitle} is Open for Select!`,
        description: `Want to shop ${campaignName}?`,
        cta: 'Reactivate Now',
        link: 'reactivate-my-membership',
      },
      mid: {
        icon: 'fal fa-box',
        title: `${campaignTitle} All Access Will Open Soon`,
        description: `Want to shop ${campaignName}?`,
        cta: 'Reactivate Now',
        link: 'reactivate-my-membership',
      },
      window_two: {
        icon: 'fal fa-box-open',
        title: `${campaignTitle} is Open!`,
        description: `Want to shop ${campaignName}?`,
        cta: 'Reactivate Now',
        link: 'reactivate-my-membership',
      },
    },
    club: {
      before_start: {
        icon: 'fal fa-lock',
        title: `${campaignTitle} Annual Access Opens Soon`,
        description: 'Want to shop Add-Ons?',
        cta: 'Activate My Membership',
        link: 'activate-box-subscription',
      },
      window_one: {
        icon: 'fal fa-lock',
        title: `${campaignTitle} Annual Access is Open`,
        description: 'Want to shop Add-Ons?',
        cta: 'Activate My Membership',
        link: 'activate-box-subscription',
      },
      mid: {
        icon: 'fal fa-lock',
        title: `${campaignTitle} All Access Opens Soon`,
        description: 'Want to shop Add-Ons?',
        cta: 'Activate My Membership',
        link: 'activate-box-subscription',
      },
      window_two: {
        icon: 'fal fa-lock',
        title: `${campaignTitle} All Access is Open`,
        description: 'Want to shop Add-Ons?',
        cta: 'Activate My Membership',
        link: 'activate-box-subscription',
      },
    },
    cancelled_with_zero_boxes: {
      before_start: {
        icon: 'fal fa-box-opened',
        title: `${campaignTitle} Will Open Soon`,
        description: `Want to shop ${campaignName}?`,
        cta: 'Reactivate Now',
        link: 'reactivate-my-membership',
      },
      window_one: {
        icon: 'fal fa-box-open',
        title: `${campaignTitle} is Open for Select!`,
        description: `Want to shop ${campaignName}?`,
        cta: 'Reactivate Now',
        link: 'reactivate-my-membership',
      },
      mid: {
        icon: 'fal fa-box',
        title: `${campaignTitle} All Access Will Open Soon`,
        description: `Want to shop ${campaignName}?`,
        cta: 'Reactivate Now',
        link: 'reactivate-my-membership',
      },
      window_two: {
        icon: 'fal fa-box-open',
        title: `${campaignTitle} is Open!`,
        description: `Want to shop ${campaignName}?`,
        cta: 'Reactivate Now',
        link: 'reactivate-my-membership',
      },
    },
    social: {
      icon: 'fal fa-lock',
      title: 'Oops! You Don’t Have Access',
      description: 'Your cart is only accessible if you’re a',
      secondDescription: 'member or if the sale is live.',
      cta: 'START MEMBERSHIP',
      link: 'activate-box-subscription',
    },
  };

  const getCopy = (element) =>
    get(
      copy,
      closedForSocialUser
        ? `social.${element}`
        : `${userRole}.${campaignStatus.toLowerCase()}.${element}`
    );

  const link = getCopy('link');
  const buttonAction =
    isOnOwnPage && page === link
      ? closeCart
      : () => {
          global.window.location.href = `https://${hostname}/${link}`;
        };

  return (
    <EmptyState
      icon={getCopy('icon')}
      title={getCopy('title')}
      description={getCopy('description')}
      secondDescription={getCopy('secondDescription')}
      cta={getCopy('cta')}
      action={buttonAction}
    />
  );
};

export default ClosedSale;
