import { useEffect, useState } from 'react';
import * as LDClient from 'launchdarkly-js-client-sdk';
import { getLDClientId } from '../../services/utils/getLdClientId';

export function useBootstrapLD(env, userId, userDataLoading) {
  const [socialEcommFF, setSocialEcommFF] = useState(null);
  const [disableTeamCartsAndAvatarsFF, setDisableTeamCartsAndAvatarsFF] = useState(null);
  const [socialEcommFlagReady, setSocialEcommFlagReady] = useState(false);
  const [disableTeamCartsAndAvatarsFlagReady, setDisableTeamCartsAndAvatarsFlagReady] =
    useState(false);
  const [disableAvatarsFF, setDisableAvatarsFF] = useState(null);
  const [disableAvatarsReady, setDisableAvatarsReady] = useState(false);
  const [freeTierShopAccessFF, setFreeTierShopAccessFF] = useState(null);
  const [freeTierShopAccessReady, setFreeTierShopAccessReady] = useState(false);
  const [shoppingCreditFF, setShoppingCreditFF] = useState(false);
  const [shoppingCreditReady, setShoppingCreditReady] = useState(false);
  const [alternateSubscriptionsReady, setAlternateSubscriptionsReady] = useState(false);
  const [selfCheckoutFF, setSelfCheckoutFF] = useState(false);
  const [selfCheckoutReady, setSelfCheckoutReady] = useState(false);
  const [newMemberOnboardingFF, setNewMemberOnboardingFF] = useState(false);
  const [newMemberOnboardingReady, setNewMemberOnboardingReady] = useState(false);

  const clientId = getLDClientId(env);

  useEffect(() => {
    if (!userDataLoading) {
      const ldclient = LDClient.initialize(clientId, {
        key: userId || 'anonymous',
      });
      ldclient.on('ready', () => {
        const socialEcommLD = ldclient.variation('social-ecomm', false);
        setSocialEcommFF(socialEcommLD);
        setSocialEcommFlagReady(true);
        const disableTeamCartsAndAvatarsLD = ldclient.variation(
          'disable-team-carts-and-avatars',
          false
        );
        setDisableTeamCartsAndAvatarsFF(disableTeamCartsAndAvatarsLD);
        setDisableTeamCartsAndAvatarsFlagReady(true);
        const disableAvatarsLD = ldclient.variation('disable-avatars', false);
        setDisableAvatarsFF(disableAvatarsLD);
        setDisableAvatarsReady(true);
        const shoppingCreditLD = ldclient.variation('shopping-credit', false);
        setShoppingCreditFF(shoppingCreditLD);
        setShoppingCreditReady(true);
        const freeTierShopAccessLD = ldclient.variation('free-tier-evergreen-shop-access', false);
        setFreeTierShopAccessFF(freeTierShopAccessLD);
        setFreeTierShopAccessReady(true);
        setAlternateSubscriptionsReady(true);
        const selfCheckout = ldclient.variation('self-checkout', false);
        setSelfCheckoutFF(selfCheckout);
        setSelfCheckoutReady(true);
        const newMemberOnboarding = ldclient.variation('new-member-onboarding', false);
        setNewMemberOnboardingFF(newMemberOnboarding);
        setNewMemberOnboardingReady(true);
      });
      ldclient.on('change:social-ecomm', (value) => {
        setSocialEcommFF(value);
      });
      ldclient.on('change:disable-team-carts-and-avatars', (value) => {
        setDisableTeamCartsAndAvatarsFF(value);
      });
      ldclient.on('change:disable-avatars', (value) => {
        setDisableAvatarsFF(value);
      });
      ldclient.on('change:free-tier-evergreen-shop-access', (value) => {
        setDisableAvatarsFF(value);
      });
      ldclient.on('change:shopping-credit', (value) => {
        setShoppingCreditFF(value);
      });
    }
  }, [userId, userDataLoading]);

  return {
    socialEcommFF,
    disableTeamCartsAndAvatarsFF,
    socialEcommFlagReady,
    disableTeamCartsAndAvatarsFlagReady,
    disableAvatarsFF,
    disableAvatarsReady,
    freeTierShopAccessFF,
    freeTierShopAccessReady,
    shoppingCreditFF,
    shoppingCreditReady,
    alternateSubscriptionsReady,
    selfCheckoutFF,
    selfCheckoutReady,
    newMemberOnboardingFF,
    newMemberOnboardingReady,
  };
}
export default useBootstrapLD;
