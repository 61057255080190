import React from 'react';
import { PLAN_TYPES } from '../services/utils/types';

const DataContext = React.createContext({
  userData: {},
  isSocialUser: null,
  initialCredits: {},
  socialCreditData: {},
  teamId: null,
  teamAvatars: [],
  teamAvatarsLoading: null,
  socialEcommFF: null,
  disableTeamCartsAndAvatarsFF: null,
  disableAvatarsFF: null,
  onClickProduct: () => {},
  openInviteModal: () => {},
  shoppingCreditFF: null,
  isNewMemberOnboarding: null,
  isNewMemberFlow: null,
  newMemberState: null,
});

const DataContextProvider = ({
  userData,
  initialCredits,
  socialCreditData,
  teamId,
  teamAvatars,
  teamAvatarsLoading,
  socialEcommFF,
  disableTeamCartsAndAvatarsFF,
  disableAvatarsFF,
  onClickProduct,
  openInviteModal,
  children,
  shoppingCreditFF,
  isNewMemberOnboarding,
  isNewMemberFlow,
  newMemberState,
}) => (
  <DataContext.Provider
    // TODO: Add useMemo here
    // eslint-disable-next-line react/jsx-no-constructed-context-values
    value={{
      userData,
      isSocialUser: userData?.planCode === PLAN_TYPES.social,
      initialCredits,
      socialCreditData,
      teamId,
      teamAvatars,
      socialEcommFF,
      teamAvatarsLoading,
      disableTeamCartsAndAvatarsFF,
      disableAvatarsFF,
      onClickProduct,
      openInviteModal,
      shoppingCreditFF,
      isNewMemberOnboarding,
      isNewMemberFlow,
      newMemberState,
    }}
  >
    {children}
  </DataContext.Provider>
);

export const useDataContext = () => {
  const context = React.useContext(DataContext);
  return context;
};

export default DataContextProvider;
