import { getEnvUsingWindowLocation } from './utils/envUtils';
import { IEnvironmentVariables, IGetEnv } from '../interfaces';
import EnvironmentNames from '../interfaces/EnvironmentNames';

export const getEnv = (getEnvProps?: IGetEnv): IEnvironmentVariables => {
  const enF = getEnvProps?.env || getEnvUsingWindowLocation();

  if (enF === EnvironmentNames.LOCAL) {
    return { ...getEnv({ env: EnvironmentNames.DEV }), name: EnvironmentNames.LOCAL };
  }

  if (enF === EnvironmentNames.PROD) {
    return {
      name: EnvironmentNames.PROD,
      REACT_APP_NAVIGATOR_URL: 'https://universal-navigation-api.fabfitfun.com',
      REACT_APP_USER_INFO_URL: 'https://universal-navigation-api.fabfitfun.com/menu/user-data',
      REACT_APP_BASE_URL: 'https://fabfitfun.com',
      REACT_APP_TIMER_URL: 'https://universal-navigation-api.fabfitfun.com/timer',
      REACT_APP_NEW_MEMBER_CAMPAIGN_URL:
        'https://customize-api.fabfitfun.com/customize/new-members/self/campaign-state',
      REACT_APP_NEW_MEMBER_API_URL: 'https://new-member-api.fabfitfun.com',
      REACT_APP_BANNER_URL: 'https://homepage-api.fabfitfun.com/megaphone-banners/current',
      REACT_APP_ADDONS_URL: 'https://fabfitfun.com/add-ons',
      REACT_APP_EDIT_URL: 'https://fabfitfun.com/edit',
      REACT_APP_ACCOUNT_INFO_URL: 'https://user.fabfitfun.com/users/self/accounts',
      REACT_APP_LOCALIZATION_URL: 'https://localization-api.fabfitfun.com',
      REACT_APP_LOGIN_URL: 'https://login.fabfitfun.com',
      REACT_APP_USER_URL: 'https://user.fabfitfun.com/users',
      REACT_APP_GROWTH_URL: 'https://growth-api.fabfitfun.com',
      REACT_APP_MEMBERSHIP_INFO_URL: 'https://user.fabfitfun.com/users/memberships/self',
      REACT_APP_CUSTOMIZE_API_URL: 'https://customize-api.fabfitfun.com',
      REACT_APP_QUESTIONNAIRE_API_URL:'https://questionnaire.fabfitfun.com',
    };
  }

  return {
    name: enF,
    REACT_APP_NAVIGATOR_URL: `https://universal-navigation-api.fff-${enF}.com`,
    REACT_APP_USER_INFO_URL: `https://universal-navigation-api.fff-${enF}.com/menu/user-data`,
    REACT_APP_BASE_URL: `https://fff-${enF}.com`,
    REACT_APP_TIMER_URL: `https://universal-navigation-api.fff-${enF}.com/timer`,
    REACT_APP_NEW_MEMBER_CAMPAIGN_URL: `https://customize-api.fff-${enF}.com/customize/new-members/self/campaign-state`,
    REACT_APP_NEW_MEMBER_API_URL: `https://new-member-api.fff-${enF}.com`,
    REACT_APP_BANNER_URL: `https://homepage-api.fff-${enF}.com/megaphone-banners/current`,
    REACT_APP_ADDONS_URL: `https://fff-${enF}.com/add-ons`,
    REACT_APP_EDIT_URL: `https://fff-${enF}.com/edit`,
    REACT_APP_ACCOUNT_INFO_URL: `https://user.fff-${enF}.com/users/self/accounts`,
    REACT_APP_LOCALIZATION_URL: 'https://localization-api.fff-dev.com',
    REACT_APP_LOGIN_URL: `https://login.fff-${enF}.com`,
    REACT_APP_USER_URL: 'https://user.fff-dev.com/users',
    REACT_APP_GROWTH_URL: 'https://growth-api.fff-dev.com',
    REACT_APP_MEMBERSHIP_INFO_URL: 'https://user.fff-dev.com/users/memberships/self',
    REACT_APP_CUSTOMIZE_API_URL: 'https://customize-api.fff-dev.com',
    REACT_APP_QUESTIONNAIRE_API_URL:'https://questionnaire.fff-dev.com',
  };
};
