import React, { useContext } from 'react';
import classNames from 'classnames';
import GlobalCart from '@fff-web/fff-cart';
import {
  CTA_TRACK_LABEL,
  createRoutes,
  chosenRouteBaseOnVariation,
  variationMapDesktop,
  renderButtonLabel,
  createTrackingData,
} from '../../utils/prospectGlobalNav';
import { isVariation } from '../../helpers/functions/isVariation';
import { ProspectNavItems } from './ProspectNavItems';
import { ProspectNavContext } from '../../context/ProspectNavContext';
import styles from './ProspectHeaderContainer.module.css';
import { GROWTH_GLOBAL_NAV } from '../../utils/featureFlagVariants/growth-global-nav';

const {
  VARIATION_A, VARIATION_B, VARIATION_C, VARIATION_D,
} = GROWTH_GLOBAL_NAV;

const ProspectNavVariants = ({ isInView }) => {
  const {
    env, mainProps, segmentTrackingData, isMobile, isTablet, isDesktop
  } = useContext(ProspectNavContext);

  const {
    handleCTA, labelCTA, showCart, variationKey, stickyCartPage, isLoginPage
  } = mainProps;
  const stickyCart = !isInView && stickyCartPage;
  const baseUrl = env.REACT_APP_BASE_URL;
  const loginUrl = env.REACT_APP_LOGIN_BASE_URL;
  const isVariationA = isVariation(variationKey, VARIATION_A);
  const isVariationB = isVariation(variationKey, VARIATION_B);
  const isVariationC = isVariation(variationKey, VARIATION_C);
  const isVariationD = isVariation(variationKey, VARIATION_D);

  const shouldShowCard = showCart && variationKey !== VARIATION_A;

  const routes = chosenRouteBaseOnVariation(
    createRoutes(baseUrl, loginUrl, isLoginPage),
    variationKey,
    variationMapDesktop,
  );

  const navigationClass = classNames(styles.navigation, {
    [styles.navigationVariationB]: isVariationB,
    [styles.navigationVariationC]: isVariationC,
    [styles.navigationVariationD]: isVariationD,
  });

  const primaryCTALabel = renderButtonLabel(labelCTA, !isDesktop);

  const handleCTAOnClick = () => {
    if (handleCTA) {
      handleCTA();
    }

    createTrackingData({
      ctaName: CTA_TRACK_LABEL,
      segmentTrackingData,
    });
  };

  return (
    <>
      {(!isMobile && !isTablet) && (
        <nav
          data-testid="desktop-menu"
          aria-label="navigation menu"
          className={navigationClass}
        >
          <ul
            className={classNames(
              styles.listWrapper,
              {
                [styles.noLogin]: isLoginPage,
              }
            )}
            role="menu"
          >
            <ProspectNavItems routes={routes} />
          </ul>
        </nav>
      )}
      {shouldShowCard && (
        <div className={classNames(styles.globalCart, { 'sticky-cart': stickyCart })}>
          <GlobalCart {...mainProps} env={env.name} />
        </div>
      )}
      {isVariationA && (
        <button
          type="button"
          className={styles.joinCTA}
          onClick={handleCTAOnClick}
          data-testid="join-cta"
        >
          {primaryCTALabel}
        </button>
      )}
    </>
  );
};

export default ProspectNavVariants;
