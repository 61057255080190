import {
  Toast,
  dismissAll,
  showToast,
  showErrorToast,
  showSuccessToast,
  showInfoToast,
  showWarningToast,
  showCustomToast,
} from './Toast';

export default Toast;

export { Toast, dismissAll, showToast, showErrorToast, showSuccessToast, showInfoToast, showWarningToast, showCustomToast };
