import get from 'lodash/get';
import { HOSTNAMES } from '../utils/env';
import { cookie } from '../utils/cookie';

const requestJson = async ({ url, method, env, headers, body }) => {
  const isLocalAceApi = url.includes('localhost:8089');
  const origin = get(HOSTNAMES, env);
  const defaultHeaders = {
    'FFF-Auth': 'V1.1',
    Origin: origin,
    ...(isLocalAceApi && { Authorization: `Bearer ${cookie.get('Jwt')}` }),
  };
  const response = await fetch(url, {
    method,
    mode: 'cors',
    headers: {
      ...defaultHeaders,
      ...headers,
    },
    body,
    ...(!isLocalAceApi && { credentials: 'include' }),
  });
  const parsedResponse = await response.json();
  if (!response.ok) throw parsedResponse;
  return parsedResponse;
};

export default requestJson;
