import React from 'react';
import PropTypes from 'prop-types';
import { trackElement } from '@fff-web/fff-analytics';

import Timeline from '../Timeline';
import CollapsiblePanel from '../CollapsiblePanel';
import './SeasonalTimeline.css';
import {
  handleTrackAndRedirect,
  trackUpgradeStarted,
  trackInviteStarted,
  trackReactivationStarted,
} from '../../utils/track';
import {
  INVITE_FRIEND_PAGE_URL,
  UPGRADE_PAGE_URL,
  REACTIVATE_MY_MEMBERSHIP_PAGE_URL,
  HOMEPAGE_EXPERIENCE,
} from '../../utils/constants';

const CTAS = {
  invite: 'SEND A FREE BOX',
  upgrade: 'UPGRADE TO ANNUAL',
  reactivate: 'REACTIVATE',
  viewEvents: 'VIEW EVENTS',
  activateMembership: 'ACTIVATE MEMBERSHIP',
};
const SeasonalTimeline = ({
  isExpired,
  isNotSubscribed,
  data: { timelineSteps, accountPlan },
  screenMode,
  subscriptionType,
  subscriptionState,
  role,
}) => {
  const handleReactivateOnClick = () => {
    // this is not the same as isExpired, isExpired includes cancelled type
    const subscriptionTypeExpired = subscriptionState === 'EXPIRED';
    handleTrackAndRedirect(
      () => trackReactivationStarted(CTAS.reactivate, subscriptionTypeExpired, subscriptionType),
      REACTIVATE_MY_MEMBERSHIP_PAGE_URL
    );
  };

  const handleSendFreeBoxClick = () => {
    handleTrackAndRedirect(
      () => trackInviteStarted(CTAS.invite, HOMEPAGE_EXPERIENCE),
      INVITE_FRIEND_PAGE_URL
    );
  };

  const handleClickUpgrade = () => {
    handleTrackAndRedirect(() => trackUpgradeStarted(CTAS.upgrade), UPGRADE_PAGE_URL);
  };

  const handleClickViewEvents = () => {
    handleTrackAndRedirect(
      () => trackElement({ elementName: CTAS.viewEvents }),
      'https://get.fabfitfun.com/upcoming/'
    );
  };

  const showInviteCTA = (accountPlan === 'ANNUAL' && !isNotSubscribed) || role === 'CLUB';

  return (
    <section className="seasonal-timeline">
      <div className="seasonal-timeline__container">
        <CollapsiblePanel title="SEASONAL TIMELINE" screenMode={screenMode}>
          <>
            <div className="seasonal-timeline__timeline__buttons">
              <div className="seasonal-timeline__timeline">
                <Timeline nodeBoxes={timelineSteps} />
              </div>
              <div className="seasonal-timeline__buttons">
                {isNotSubscribed && (
                  <a className="fff-btn-primary" href="/activate-membership">
                    {CTAS.activateMembership}
                  </a>
                )}
                {/* eslint-disable no-nested-ternary */}
                {isExpired && !isNotSubscribed ? (
                  <button
                    type="button"
                    className="fff-btn-primary"
                    onClick={handleReactivateOnClick}
                  >
                    {CTAS.reactivate}
                  </button>
                ) : showInviteCTA ? (
                  <button
                    type="button"
                    className="fff-btn-primary"
                    onClick={handleSendFreeBoxClick}
                  >
                    {CTAS.invite}
                  </button>
                ) : (
                  !isNotSubscribed && (
                    <button type="button" className="fff-btn-primary" onClick={handleClickUpgrade}>
                      {CTAS.upgrade}
                    </button>
                  )
                )}
                <button
                  type="button"
                  className="fff-btn-primary-black-outline"
                  onClick={handleClickViewEvents}
                  rel="noreferrer"
                >
                  {CTAS.viewEvents}
                </button>
              </div>
            </div>
            <div className="divider" />
          </>
        </CollapsiblePanel>
      </div>
    </section>
  );
};

SeasonalTimeline.defaultProps = {
  data: {},
  screenMode: 'mobile',
  isExpired: false,
  isNotSubscribed: false,
  role: '',
};

SeasonalTimeline.propTypes = {
  screenMode: PropTypes.string,
  data: PropTypes.shape({
    accountPlan: PropTypes.string,
    currentTimelineSteps: PropTypes.arrayOf(PropTypes.string),
    timelineSteps: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        message: PropTypes.string,
        order: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        date: PropTypes.string,
        group: PropTypes.string,
      })
    ),
  }),
  isExpired: PropTypes.bool,
  isNotSubscribed: PropTypes.bool,
  role: PropTypes.string,
};

export default SeasonalTimeline;
