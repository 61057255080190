import React, { useRef, useEffect, useState } from 'react';
import _ from 'lodash';
import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';

import Header from '../Header/Header';
import LoadingCart from '../LoadingCart/LoadingCart';
import Tabs from '../Tabs/Tabs';
import FlashSales from '../FlashSales/FlashSales';
import ClosedSale from '../ClosedSale/ClosedSale';
import Cart from '../Cart/Cart';
import SocialCartContainer from '../SocialCartContainer/SocialCartContainer';
import EmptyState from '../EmptyState/EmptyState';

import { useInternalContext } from '../../../context/InternalContext';
import { useDataContext } from '../../../context/DataContext';
import useWindowSize from '../../../shared/hooks/useWindowSize';

import { WINDOW_SIZE } from '../../../services/utils/device';
import { SUBSCRIPTION_STATE } from '../../../services/utils/types';
import {
  campaignHasEnded,
  getIsOnOwnPage,
  CAMPAIGN_STATUS,
  SALE_TYPES,
} from '../../../services/utils/campaign';

import styles from './CartContent.module.css';

const TABS = {
  SHOP_TAB: 1,
  SALE_TAB: 0,
};

const CartContent = ({
  activeCampaign,
  shopCampaignData,
  teamCarts,
  teamCartsLoading,
  displaySocialCart,
  isLoading,
  saleCartDataState,
  setSaleCartDataState,
  shopCartDataState,
  setShopCartDataState,
  closeCart,
  receiveUpdateCart,
  receiveCartProducts,
  freeTierShopAccessFF,
  isPublicShopping,
  itemsCount,
  seasonalPlanPrice,
  selfCheckoutFF,
}) => {
  const { hostname, isEditPage, isShopPage, page } = useInternalContext();
  const { isSocialUser, userData, isNewMemberOnboarding, isNewMemberFlow } = useDataContext();
  const itemsContainerRef = useRef();
  const cartContainerRef = useRef();
  const windowSize = useWindowSize();

  const activeCampaignType = _.get(activeCampaign, 'type', SALE_TYPES.SEASON);
  const closedForSocialUser = isSocialUser && !displaySocialCart;
  const isOwnPage = getIsOnOwnPage(page, activeCampaignType);
  const isExpired = userData?.subscriptionState === SUBSCRIPTION_STATE.expired;
  const redirectToReactivate = () => {
    global.window.location.href = `https://${hostname}/reactivate-my-membership`;
  };

  const [activeTab, setActiveTab] = useState(isShopPage ? TABS.SHOP_TAB : TABS.SALE_TAB);

  // body scroll lock
  useEffect(() => {
    const elemRef = itemsContainerRef.current || cartContainerRef.current;
    if (windowSize === WINDOW_SIZE.mobile && elemRef) {
      clearAllBodyScrollLocks();
      disableBodyScroll(elemRef);
    }
    return () => clearAllBodyScrollLocks();
  }, [windowSize, activeTab, teamCartsLoading]);

  // pre select tabs
  useEffect(() => {
    if (isShopPage || !activeCampaign) {
      setActiveTab(TABS.SHOP_TAB);
    } else {
      setActiveTab(TABS.SALE_TAB);
    }
  }, [isShopPage, activeCampaign]);

  const renderSaleContent = () => {
    if (isPublicShopping) {
      return (
        <Cart
          receiveUpdateCart={isOwnPage && receiveUpdateCart}
          cartData={shopCartDataState}
          campaignData={activeCampaign}
          setCartData={setShopCartDataState}
          closeCart={closeCart}
          itemsContainerRef={itemsContainerRef}
          tab="Flash Sale"
          isPublicShopping
          seasonalPlanPrice={seasonalPlanPrice}
          selfCheckoutFF={selfCheckoutFF}
        />
      );
    }
    if (activeCampaign && !campaignHasEnded(activeCampaign.campaignStatus)) {
      if (activeCampaign.userAccess) {
        return (
          <Cart
            receiveUpdateCart={isOwnPage && receiveUpdateCart}
            cartData={saleCartDataState}
            campaignData={activeCampaign}
            setCartData={setSaleCartDataState}
            closeCart={closeCart}
            itemsContainerRef={itemsContainerRef}
            tab="Flash Sale"
            isPublicShopping={isPublicShopping}
            seasonalPlanPrice={seasonalPlanPrice}
            selfCheckoutFF={selfCheckoutFF}
          />
        );
      }
      return (
        <ClosedSale
          campaignStatus={_.get(activeCampaign, 'campaignStatus', CAMPAIGN_STATUS.ended)}
          campaignType={activeCampaignType}
          isOnOwnPage={isOwnPage}
          closedForSocialUser={closedForSocialUser}
          closeCart={closeCart}
        />
      );
    }
    return <FlashSales isOnOwnPage={getIsOnOwnPage(page, 'SEASON')} closeCart={closeCart} />;
  };

  const renderSecondTab = () => {
    if (displaySocialCart) {
      return (
        <SocialCartContainer
          cartData={teamCarts}
          variantQuantities={saleCartDataState.cartDetails.variantQuantities}
          setCartData={setSaleCartDataState}
          receiveUpdateCart={isEditPage && receiveUpdateCart}
          campaignData={activeCampaign}
          closeCart={closeCart}
          ownCartVariants={saleCartDataState.cartVariants}
          teamCartsLoading={teamCartsLoading}
          isEditPage={isEditPage}
          itemsContainerRef={itemsContainerRef}
        />
      );
    }
    if (!shopCampaignData.userAccess || (isExpired && !freeTierShopAccessFF)) {
      return (
        <EmptyState
          icon="fal fa-shopping-bag"
          title="Want to browse The Shop?"
          description="Access to The Shop is a member perk."
          cta="Reactivate Now"
          action={redirectToReactivate}
        />
      );
    }
    return (
      <Cart
        receiveUpdateCart={isShopPage && receiveUpdateCart}
        receiveCartProducts={isShopPage && receiveCartProducts}
        cartData={shopCartDataState}
        campaignData={shopCampaignData}
        setCartData={setShopCartDataState}
        closeCart={closeCart}
        itemsContainerRef={itemsContainerRef}
        tab="The Shop"
        isPublicShopping={isPublicShopping}
        seasonalPlanPrice={seasonalPlanPrice}
        selfCheckoutFF={selfCheckoutFF}
      />
    );
  };

  const showSecondaryTab = !isNewMemberOnboarding || !isNewMemberFlow;

  return (
    <div className={styles.cart} ref={cartContainerRef}>
      <div className={styles.cartContentWrapper}>
        {showSecondaryTab && (
          <Header
            displaySocialCart={displaySocialCart}
            closedForSocialUser={closedForSocialUser}
            closeCart={closeCart}
            isPublicShopping={isPublicShopping}
            itemsQty={itemsCount}
          />
        )}
        {isLoading ? (
          <LoadingCart windowSize={windowSize} />
        ) : (
          <>
            {(!isPublicShopping || closedForSocialUser) && (
              <Tabs
                data-testid="tabs"
                activeCampaign={activeCampaign}
                activeTab={activeTab}
                setActiveTab={setActiveTab}
                saleItemsCount={_.get(saleCartDataState, 'itemsCount')}
                shopItemsCount={_.get(shopCartDataState, 'itemsCount')}
                teamCount={_.size(_.get(teamCarts, 'userIds'))}
                displaySocialCart={displaySocialCart}
                isNewMemberOnboarding={isNewMemberOnboarding}
                showSecondaryTab={showSecondaryTab}
              />
            )}
            {activeTab === 0 || isPublicShopping ? renderSaleContent() : renderSecondTab()}
          </>
        )}
      </div>
    </div>
  );
};

export default CartContent;
