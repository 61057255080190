import React, { useState } from 'react';
import { BoxFull, Times } from '@fff-web/fff-ui-shared/Icon';
import IntroProductModal from '@fff-web/fff-ui-shared/IntroProductModal';
import ButtonLink from '@fff-web/fff-ui-shared/ButtonLink';
import BANNER_TYPE from '../../../utils/publicShop';
import { VARIANT_TYPES } from '../../../services/utils/types';
import { formatCurrency } from '../../../services/utils/currency';
import { updateMembershipHandler } from '../../../services/utils/update-cart';
import styles from './Banner.module.css';

const createContent = (title, description, note, onClickEvent) => (
  <div className={styles.content}>
    <BoxFull className={styles.box} />
    <div>
      <p className={styles.title}>{title}</p>
      <p className={styles.description}>
        {description}{' '}
        <ButtonLink
          fontSize={12}
          type="black"
          onClick={onClickEvent}
          label="Learn more"
          permaUnderline
          role="button"
        />
      </p>
      <p className={styles.note}>{note}</p>
    </div>
  </div>
);

const Banner = ({
  type,
  cartVariants,
  receiveUpdateCart,
  receiveCartProducts,
  env,
  setCartData,
  isPublicShopping,
  seasonalPlanPrice,
}) => {
  let Content;
  let Button;

  const [modalIsOpen, setModalIsOpen] = useState(false);
  const openModal = () => setModalIsOpen(true);
  const closeModal = () => setModalIsOpen(false);
  const savePrice =
    type === BANNER_TYPE.unlockMemberPricing
      ? cartVariants.reduce((savePriceTotal, cartVariant) => {
          if (cartVariant.variantType === VARIANT_TYPES.prospectSingle) {
            if (cartVariant.price && cartVariant.salePrice) {
              const diff = Number(cartVariant.price) - Number(cartVariant.salePrice);
              return diff * cartVariant.quantity + savePriceTotal;
            }
          }
          return savePriceTotal;
        }, 0)
      : null;

  const updateMembership = (operation) => {
    updateMembershipHandler(
      operation,
      'SHOP',
      cartVariants,
      receiveUpdateCart,
      receiveCartProducts,
      env,
      setCartData,
      isPublicShopping
    );
  };

  if (type === BANNER_TYPE.memberPricingUnlocked) {
    Content = createContent(
      "You've added a FabFitFun Membership",
      `Continues at $${seasonalPlanPrice} per season.`,
      'Member pricing unlocked',
      openModal
    );
    Button = (
      <button
        className={styles.wrapperClose}
        onClick={() => updateMembership('REMOVE')}
        type="button"
      >
        <Times className={styles.close} />
      </button>
    );
  }

  if (type === BANNER_TYPE.unlockMemberPricing) {
    Content = createContent(
      'Add a FabFitFun Membership',
      `Continues at $${seasonalPlanPrice} per season.`,
      `Unlock member pricing and save $${formatCurrency(savePrice)}!`,
      openModal
    );
    Button = (
      <button className={styles.add} type="button" onClick={() => updateMembership('ADD')}>
        ADD {modalIsOpen}
      </button>
    );
  }

  return (
    Content && (
      <div className={styles.container}>
        {Content}
        {Button}
        <IntroProductModal
          modalIsOpen={modalIsOpen}
          closeModal={closeModal}
          planPrice={seasonalPlanPrice}
        />
      </div>
    )
  );
};

export default Banner;
