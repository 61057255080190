import React from 'react';
import track, { segmentEvents } from '../../../../services/utils/segment';
import EmptyState from '../../EmptyState/EmptyState';
import { getIsInPandora } from '../../../../services/utils/page';
import { useInternalContext } from '../../../../context/InternalContext';
import { useDataContext } from '../../../../context/DataContext';

const SEGMENT_EXPERIENCE = 'Friends Cart';

const COPY = {
  icon: 'fal fa-envelope-open-text',
  title: 'Invite Your Friends',
  description: 'You have available invites.',
  secondDescription: 'Who will you choose?',
  cta: 'send invite',
};

const trackInviteStarted = (trackingData) => {
  track(segmentEvents.inviteStarted, {
    ...trackingData,
    experience: 'Friends Cart',
    cta_name: 'SEND INVITE',
  });
};

const EmptySocialCart = ({ closeCart, trackingData }) => {
  const { hostname, isEditPage } = useInternalContext();
  const { openInviteModal, onClickProduct: onClickLinkInPandora } = useDataContext();
  const getButtonAction = () => {
    if (getIsInPandora()) {
      return () => {
        trackInviteStarted(trackingData);
        closeCart();
        if (isEditPage) {
          openInviteModal(SEGMENT_EXPERIENCE);
        } else {
          onClickLinkInPandora({
            pathname: '/edit',
            search: '?inviteOpen=true',
          });
        }
      };
    }
    return () => {
      trackInviteStarted(trackingData);
      global.window.location.href = `https://${hostname}/edit?inviteOpen=true`;
    };
  };
  const buttonAction = getButtonAction();
  // TODO change this from spread props to normal props
  // eslint-disable-next-line react/jsx-props-no-spreading
  return <EmptyState {...COPY} action={buttonAction} />;
};

export default EmptySocialCart;
