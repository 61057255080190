import { ScriptsExists } from '@fff-web/fff-utilities';
import { getHomepageHost, getZendesk } from './getEnv';
import isBrowser from './isBrowser';
import { adaStyle, adaScript, gladlyScript } from './chatSnippets';

const zendeskScriptId = 'ze-snippet';
const adaScriptId = '__ada';
const gladlyScriptId = '__gladly';

const adaScriptSrc = 'https://static.ada.support/embed2.js';

const adaDataHandle = 'fff';
const adaAIHandle = 'fff-gr';

/**
 *  Checks if the Ada button and the script exists in the page.
 * @returns {boolean} If the Ada chatbot is loaded.
 */
export const getIsAdaChatLoaded = () => {
  const adaChatButtonEl = document.getElementById('ada-entry');

  return adaChatButtonEl && ScriptsExists.scriptExistsById(adaScriptId);
};

/**
 * Toggles the state of the button between display/hide the button.
 * @param {boolean} showChatButton The initial value to display the Ada chat button.
 * @returns {void}
 */
const toggleDisplayAdaChat = (showChatButton = false) => {
  const adaChatButtonEl = document.getElementById('ada-entry');
  adaChatButtonEl.style.display = `${showChatButton ? 'initial' : 'none'}`;
};

/**
 * Removes all the existing widgets in the page.
 * @returns {void}
 */
export const removeAllWidgets = () => {
  removeScriptIfPresent(gladlyScriptId);
  removeScriptIfPresent(zendeskScriptId);
  removeScriptIfPresent(adaScriptId);
};

/**
 * Loads the Zendesk script into the page.
 * @returns {boolean} If the Zendesk chat was loaded successfully.
 */
const loadZendesk = () => {
  const zenKey = getZendesk();
  const scriptSrc = `https://static.zdassets.com/ekr/snippet.js?key=${zenKey}`;
  if (!ScriptsExists.scriptExistsById(zendeskScriptId)) {
    const blockScript = document.createElement('script');
    blockScript.id = zendeskScriptId;
    blockScript.src = scriptSrc;
    blockScript.type = 'text/javascript';
    document.head.appendChild(blockScript);
  }
  return true;
};

/**
 * Loads the Gladly script into the page.
 * @returns {boolean} If the Zendesk chat was loaded successfully.
 */
export const loadGladlyChat = () => {
  const blockScript = document.createElement('script');
  blockScript.innerHTML = gladlyScript;
  blockScript.id = gladlyScriptId;
  blockScript.type = 'text/javascript';
  document.head.appendChild(blockScript);
  window.gladlyConfig = { appId: 'fabfitfun.com' };
  return true;
};

/**
 * Loads the proper settings script and the Ada chatbot script to the page.
 * @param {object} userInfo An object containing the user info that can serve to add to the 'metaFields'.
 * @returns {void}
 */
const loadAdaChat = (userInfo, isAIAdaChat) => {
  const isAdaChatbotLoaded = getIsAdaChatLoaded();

  if (isAdaChatbotLoaded) {
    toggleDisplayAdaChat(true);
    return;
  }

  document.head.insertAdjacentHTML('beforeend', adaStyle);
  window.liveChatEnded = false;

  const adaScriptEl = document.createElement('script');
  adaScriptEl.innerHTML = adaScript;
  adaScriptEl.type = 'text/javascript';
  document.body.appendChild(adaScriptEl);
  if (userInfo?.email) {
    window.adaSettings = {
      ...window.adaSettings,
      metaFields: userInfo,
    };
  }

  /**
   * This script must be loaded after the 'window.adaSettings'
   * (added in 'adaScriptEl') is being declared.
   */
  if (!ScriptsExists.scriptExistsById(adaScriptId)) {
    const blockScript = document.createElement('script');
    blockScript.id = adaScriptId;
    blockScript.setAttribute('data-handle', isAIAdaChat ? adaAIHandle : adaDataHandle);
    blockScript.src = adaScriptSrc;
    blockScript.type = 'text/javascript';
    document.body.appendChild(blockScript);
  }
};

/**
 * Checks if a script is present in the page and if it's present
 * it's being removed from the page.
 * @param {string} scriptId The name of the script to remove.
 * @returns {void}
 */
const removeScriptIfPresent = (scriptId) => {
  const isAdaChatbotLoaded = getIsAdaChatLoaded();

  if (scriptId === adaScriptId) {
    if (isAdaChatbotLoaded) {
      toggleDisplayAdaChat();
      return;
    }
  }
  const element = document.getElementById(scriptId);

  if (element) {
    // eslint-disable-next-line no-console
    console.log(`removing scriptId ${scriptId}`);
    element.parentNode.removeChild(element);
  }
};

/**
 * Loads the chat and its settings according to the configurations provided.
 * @param {object} chatConfig The settings to be applied to the chatbot being loaded.
 * @param {object} userInfo An object containing user information that can be used to add to the 'metaFields'.
 * @returns {boolean} If the chat was loaded successfully.
 */
export const loadChat = (chatConfig, userInfo) => {
  if (!isBrowser()) {
    return false;
  }

  if (!chatConfig) {
    return false;
  }
  let currentPath = window.location && window.location.pathname ? window.location.pathname : '';

  if (currentPath !== '/') {
    // This condition is to render the chat on GET THE BOX page
    if (currentPath.endsWith('/')) {
      currentPath = currentPath.substr(0, currentPath.length - 1);
    }
  }

  if (currentPath.length === 0 && window.location.host === getHomepageHost()) {
    currentPath = '/homepage';
  }

  const isSupportPage = window.location.host.includes('support');
  const pagesWithZendeskChat = chatConfig.zendeskPages || [];
  const pagesWithGladlyChat = chatConfig.gladlyPages || [];
  const pagesWithAdaChat = chatConfig.adaPages || [];
  const pagesWithAIAdaChat = chatConfig.aiAdaPages || [];
  const isAIAdaChat = pagesWithAIAdaChat.includes(currentPath) || (isSupportPage && chatConfig.supportChat === adaScriptId);
  const useAda = pagesWithAdaChat.includes(currentPath) || (chatConfig.supportChat === adaScriptId && isSupportPage) || isAIAdaChat;
  const useZendesk = pagesWithZendeskChat.includes(currentPath) || (chatConfig.supportChat === zendeskScriptId && isSupportPage);
  const useGladly = pagesWithGladlyChat.includes(currentPath) || (chatConfig.supportChat === gladlyScriptId && isSupportPage);


  if (useGladly) {
    // eslint-disable-next-line no-console
    console.log('Gladly chat loaded');
    removeAllWidgets();
    return loadGladlyChat();
  }

  if (useZendesk) {
    // eslint-disable-next-line no-console
    console.log('Zendesk chat loaded');
    removeAllWidgets();
    return loadZendesk();
  }

  if (useAda) {
    // eslint-disable-next-line no-console
    console.log('Ada chat loaded');
    removeAllWidgets();
    removeScriptIfPresent(zendeskScriptId);
    return loadAdaChat(userInfo, isAIAdaChat);
  }
  // eslint-disable-next-line no-console
  removeAllWidgets();
  console.log('No chat loaded');
  return false;
};
