import { FilteredIdentifyProps, GetUserInfoProps, TraitsProps } from '../../interfaces';
import { formatMemberStatus, formatPlanCode, formatRole } from './formatFields';
import { getDevice } from './genericTracking';

const identifyInfo = (
  {
    userInfo,
    traits,
  }: {
    userInfo?: GetUserInfoProps,
    traits?: TraitsProps,
  },
): FilteredIdentifyProps => (
  {
    role: formatRole(traits?.role || userInfo?.user?.role || ''),
    member_status: formatMemberStatus(traits?.memberStatus || userInfo?.user?.subscriptionState || ''),
    plan_code: formatPlanCode(traits?.planCode || userInfo?.user?.planCode || ''),
    experiment_name: traits?.experimentName,
    variation_name: traits?.variationName,
    feature_flag_name: traits?.featureFlagName,
    campaign_id: traits?.campaignId,
    fff_uuid: traits?.fffUUID,
    device: getDevice(),
  }
);

export default identifyInfo;
