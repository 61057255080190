import { IMembership } from "../../interfaces/IMyMembership";

interface IMemberLabelMyAccount {
  membershipInfo: IMembership;
  planCode: string;
}

interface IMemberLabelGlobalNav {
  membershipInfo: IMembership;
  role: string;
  planCode: string;
}

const planCodes = {
  annual: 'fffvipa',
  seasonal: 'fffvip'
};

const planCodesLabel = {
  [planCodes.annual]: 'Annual',
  [planCodes.seasonal]: 'Seasonal'
};

const MEMBER_LABEL = 'Member';
const SELECT_LABEL = ' / Select Member';
const SELECT_LABEL_SEASONAL = ' / Select Access';

export const getMembershipLabelMyAccount = ({ membershipInfo, planCode }: IMemberLabelMyAccount) => {
  if(!membershipInfo?.membership_state || !membershipInfo?.flags || membershipInfo?.flags.length === 0) {
    return '';
  }

  if (membershipInfo?.isRefillSubscription) {
    return MEMBER_LABEL;
  }

  if(planCode === planCodes.annual) {
    return 'Annual Member' ;
  }

  return MEMBER_LABEL;
};

export const getMembershipLabelGlobalNav = ({ membershipInfo, role, planCode }: IMemberLabelGlobalNav) => {
  if(!membershipInfo?.membership_state || !membershipInfo?.flags || membershipInfo?.flags.length === 0) {
    return '';
  }

  if (membershipInfo?.isRefillSubscription) {
    return MEMBER_LABEL;
  }

  if ((planCode === planCodes.annual || planCode === planCodes.seasonal)) {
    const isAnnual = planCode === planCodes.annual;
    const selectText = isAnnual ? SELECT_LABEL : SELECT_LABEL_SEASONAL;
    return `${planCodesLabel[planCode]}${(role === 'SELECT' || isAnnual) ? selectText : ''}`;
  }

  return MEMBER_LABEL;
};  

