import { MENU_IDS, TRAIL_REACTIVATION_URL } from './constants';

const getDynamicLinks = (env) => ({
  REACT_APP_MOBILE_BANNER: `${env.REACT_APP_MOBILE_DYNAMIC_LINK_BASE_URL}${
    env.name === 'prod' ? '/install-the-app' : '/install'
  }`,
  REACT_APP_MOBILE_BANNER_MY_ACCOUNT: `${env.REACT_APP_MOBILE_DYNAMIC_LINK_BASE_URL}/my_account`,
  REACT_APP_MOBILE_BANNER_ORDERS: `${env.REACT_APP_MOBILE_DYNAMIC_LINK_BASE_URL}/Orders`,
  REACT_APP_MOBILE_BANNER_INVOICE: `${env.REACT_APP_MOBILE_DYNAMIC_LINK_BASE_URL}/invoice`,
  REACT_APP_MOBILE_BANNER_BOX: `${env.REACT_APP_MOBILE_DYNAMIC_LINK_BASE_URL}/box`,
  REACT_APP_MOBILE_BANNER_TALK: `${env.REACT_APP_MOBILE_DYNAMIC_LINK_BASE_URL}/talk`,
  REACT_APP_MOBILE_BANNER_SHOP: `${env.REACT_APP_MOBILE_DYNAMIC_LINK_BASE_URL}/shop`,
  REACT_APP_MOBILE_BANNER_EDIT: `${env.REACT_APP_MOBILE_DYNAMIC_LINK_BASE_URL}/edit`,
  REACT_APP_MOBILE_BANNER_ADD_ONS: `${env.REACT_APP_MOBILE_DYNAMIC_LINK_BASE_URL}/add-ons`,
  REACT_APP_MOBILE_BANNER_RATE_AND_REVIEW: `${env.REACT_APP_MOBILE_DYNAMIC_LINK_BASE_URL}/rate_and_review`,
  REACT_APP_MOBILE_BANNER_INVITE_A_FRIEND: `${env.REACT_APP_MOBILE_DYNAMIC_LINK_BASE_URL}/InviteFriends`,
});

export const getAppMobileLink = (env) => {
  const dynamicLinks = getDynamicLinks(env);
  const { pathname } = window.location;
  switch (true) {
    case pathname.endsWith('my-account/'):
      return dynamicLinks.REACT_APP_MOBILE_BANNER_MY_ACCOUNT;
    case pathname.endsWith('/orders'):
      return dynamicLinks.REACT_APP_MOBILE_BANNER_ORDERS;
    case pathname.endsWith('/invoice-history'):
      return dynamicLinks.REACT_APP_MOBILE_BANNER_INVOICE;
    case pathname.endsWith('/my-box') || pathname.endsWith('/my-subscriptions'):
      return dynamicLinks.REACT_APP_MOBILE_BANNER_BOX;
    case pathname.endsWith('/invite-friends'):
      return dynamicLinks.REACT_APP_MOBILE_BANNER_INVITE_A_FRIEND;
    case pathname.includes('talk'):
      return dynamicLinks.REACT_APP_MOBILE_BANNER_TALK;
    case pathname.includes('shop'):
      return dynamicLinks.REACT_APP_MOBILE_BANNER_SHOP;
    case pathname.includes('edit'):
      return dynamicLinks.REACT_APP_MOBILE_BANNER_EDIT;
    case pathname.includes('add-ons'):
      return dynamicLinks.REACT_APP_MOBILE_BANNER_ADD_ONS;
    case pathname.endsWith('rate-and-review'):
      return dynamicLinks.REACT_APP_MOBILE_BANNER_RATE_AND_REVIEW;
    default:
      return dynamicLinks.REACT_APP_MOBILE_BANNER;
  }
};

export const updateRouteForTrial = (loggedRoutes = []) => {
  return loggedRoutes.map((loggedRoute) => ({
    ...loggedRoute,
    subRoutes: loggedRoute.id === MENU_IDS.MY_BOX
      ? loggedRoute.subRoutes.map((subroute) => ({
        ...subroute,
        route: subroute.id === MENU_IDS.CUSTOMIZE ? TRAIL_REACTIVATION_URL : subroute.route,
      }))
      : loggedRoute.subRoutes,
  }));
};
