import React, { useMemo, useEffect } from 'react';
import size from 'lodash/size';
import isEmpty from 'lodash/isEmpty';

import Loader from '../../../shared/components/Loader/Loader';
import DisabledSocialCart from './DisabledSocialCart/DisabledSocialCart';
import SocialCart from './SocialCart/SocialCart';
import EmptySocialCart from './EmptySocialCart/EmptySocialCart';
import { getInviteTrackingData, getCartTrackingData } from '../../../services/utils/tracking-data';
import track, { segmentEvents } from '../../../services/utils/segment';
import { useDataContext } from '../../../context/DataContext';

const SocialCartContainer = ({
  cartData,
  variantQuantities,
  setCartData,
  campaignData,
  receiveUpdateCart,
  closeCart,
  ownCartVariants,
  teamCartsLoading,
  itemsContainerRef,
}) => {
  const { userData, teamId, disableTeamCartsAndAvatarsFF } = useDataContext();
  const { shippingRestriction } = userData;

  // track cart viewed - we only want to track this once when the cart/user data loads
  useEffect(() => {
    if (!isEmpty(userData)) {
      const trackingData = getCartTrackingData(
        userData,
        campaignData,
        cartData,
        'Friends Cart',
        teamId
      );
      track(segmentEvents.cartViewed, trackingData);
    }
  }, [userData]);

  const inviteTrackingData = useMemo(() => {
    if (!isEmpty(userData) && !isEmpty(campaignData)) {
      return getInviteTrackingData(userData, campaignData);
    }
    return null;
  }, [userData, campaignData]);

  if (disableTeamCartsAndAvatarsFF) {
    return <DisabledSocialCart />;
  }
  if (teamCartsLoading) {
    return <Loader />;
  }
  if (!size(cartData.cartVariants) || !teamId) {
    return <EmptySocialCart closeCart={closeCart} trackingData={inviteTrackingData} />;
  }

  return (
    <div className="global-cart-container">
      <SocialCart
        cartData={cartData}
        variantQuantities={variantQuantities}
        setCartData={setCartData}
        userShippingRestriction={shippingRestriction}
        campaignData={campaignData}
        receiveUpdateCart={receiveUpdateCart}
        closeCart={closeCart}
        ownCartVariants={ownCartVariants}
        itemsContainerRef={itemsContainerRef}
      />
    </div>
  );
};

export default SocialCartContainer;
