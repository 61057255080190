export const getToastNotification = async (env, location, uid) => {
  const response = await fetch(
    `${env.REACT_APP_NAVIGATOR_URL}/notification/${location}/${uid}`,
    {
      credentials: 'include',
    },
  );

  if (!response.ok) {
    throw new Error(`Failed to fetch notification: ${response.statusText}`);
  }

  if (response.status === 204) {
    return null;
  }

  return await response.json();
};

export const snoozeToastNotification = async (env, location, uid) => {
  try {
    const response = await fetch(
      `${env.REACT_APP_NAVIGATOR_URL}/notification/snooze/${location}/${uid}`,
      {
        method: 'POST',
        credentials: 'include',
      },
    );

    if (response.status === 200) {
      return true;
    }

    const responseText = await response.text();
    throw new Error(`Failed to snooze notification: ${responseText}`);
  } catch (error) {
    console.error('Error snoozing toast notification:', error);
    throw error;
  }
};
