import { useState, useEffect } from 'react';
import { FFFStorage, Compare } from '@fff-web/fff-utilities';
import { getFooterContent } from '../services';

const footerContentKey = 'footer-content';
const cacheDurationInMs = 15 * 60 * 1000; //15 minutes

const useFooterContent = () => {
  const [footerContent, setFooterContent] = useState(
    FFFStorage.getFromStorage({ key: footerContentKey })
  );

  useEffect(() => {
    const footerCache = FFFStorage.getFromStorage({ key: footerContentKey });
    if (footerCache) {
      if (!Compare.isEqualObject(footerContent, footerCache)) {
        setFooterContent(footerCache);
      }
    } else {
      getFooterContent().then((content) => {
        setFooterContent(content);
        FFFStorage.setToStorage({
          key: footerContentKey,
          value: content,
          durationInMs: cacheDurationInMs,
        });
      });
    }
  }, [footerContent]);

  return { footerContent, defaultYear: new Date().getFullYear().toString() };
};

export default useFooterContent;
