import get from 'lodash/get';

import { CAMPAIGN_TYPE_LINKS } from './campaign';
import { HOSTNAMES } from './env';
import { VARIANT_TYPES } from './types';

// eslint-disable-next-line import/prefer-default-export
export const clickProductHandler = (variant, campaignType, onClickProduct, closeCart, env) => {
  const hostname = get(HOSTNAMES, env);
  const salePage = get(CAMPAIGN_TYPE_LINKS, campaignType);
  const queryParam = `product_link=${variant.shareUrl}`;
  closeCart();
  if (onClickProduct) {
    // we receive onClickProduct from pandora to prevent a full page refresh
    onClickProduct({
      pathname: `/${salePage}`,
      search: `?${queryParam}`,
      variant,
    });
  } else if (
    variant.variantType === VARIANT_TYPES.customizeAdditionalChoice &&
    variant.questionId
  ) {
    window.location.href = `/new-member-customize/?q=${variant.questionId}`;
  } else {
    global.window.location = `https://${hostname}/${salePage}?${queryParam}`;
  }
};
