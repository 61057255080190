import React, { useMemo } from 'react';
import _ from 'lodash';
import styles from './Avatars.module.css';

const Avatar = ({ url }) =>
  url ? <img src={url} alt="user avatar" /> : <i className="fas fa-user-circle" />;

const Avatars = ({ users, teamAvatars }) => {
  const mappedAvatars = useMemo(
    () =>
      _.reduce(
        teamAvatars,
        (acc, { userId, userAvatarUrl }) => ({
          ...acc,
          [userId]: userAvatarUrl,
        }),
        {}
      ),
    [teamAvatars]
  );
  const totalUsers = _.size(users);
  const additionalUsers = totalUsers > 4 ? totalUsers - 3 : null;
  const displayedList = additionalUsers ? _.slice(users, 0, 4) : users;

  return (
    <div className={styles.container}>
      {_.map(displayedList, (user, index) =>
        //this is being display in flex row reverse, so we want the last item on the bottom right to be additional users
        index === 2 && additionalUsers ? (
          <div key={index} className={styles.additionalUsers}>{`+${additionalUsers}`}</div>
        ) : (
          <Avatar key={user} url={_.get(mappedAvatars, user)} />
        )
      )}
    </div>
  );
};

export default Avatars;
