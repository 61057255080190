/* eslint-disable max-len */
import React, { SVGProps } from 'react';
import Icon from './Icon';

const BoxFull: React.FunctionComponent<SVGProps<SVGSVGElement>> = (props) => (
  <Icon width="20px" height="16px" viewBox="0 0 20 16" {...props}>
    <path
      d="M7.96875 1C6.3125 1 4.96875 2.34375 4.96875 4C4.96875 4.28125 4.75 4.5 4.46875 4.5C4.21875 4.5 3.96875 4.28125 3.96875 4C3.96875 1.8125 5.78125 0 7.96875 0C10.1875 0 12 1.8125 12 4C12 4.28125 11.75 4.5 11.5 4.5C11.2188 4.5 11 4.28125 11 4C11 2.34375 9.65625 1 7.96875 1ZM10 5.9375L17.4688 5.0625C17.9062 5 18.3438 5.25 18.5 5.65625L19.5 8C19.8125 8.71875 19.4062 9.53125 18.625 9.6875L13.6562 10.8438C13.0625 11 12.4688 10.75 12.0938 10.25L9.96875 7.28125L7.875 10.25C7.5 10.75 6.90625 11 6.3125 10.8438L1.34375 9.6875C0.5625 9.53125 0.15625 8.71875 0.46875 8L1.46875 5.65625C1.625 5.25 2.0625 5 2.5 5.0625L10 5.9375ZM1.375 8.375C1.3125 8.53125 1.40625 8.6875 1.5625 8.71875L6.53125 9.875C6.71875 9.9375 6.9375 9.84375 7.0625 9.6875L9.09375 6.84375L2.375 6.0625L1.375 8.375ZM12.9062 9.6875C13.0312 9.84375 13.25 9.9375 13.4375 9.875L18.4062 8.71875C18.5625 8.6875 18.6562 8.53125 18.5938 8.375L17.5938 6.0625L10.875 6.84375L12.9062 9.6875ZM18 10.625V12.8438C18 13.5312 17.5312 14.125 16.8438 14.3125L10.3438 15.9375C10.0938 15.9688 9.875 15.9688 9.625 15.9375L3.125 14.3125C2.4375 14.125 1.96875 13.5312 1.96875 12.8438V10.625L2.96875 10.9062V12.8438C2.96875 13.0625 3.125 13.2812 3.375 13.3125L9.46875 14.8438V10.5C9.46875 10.25 9.71875 10 9.96875 10C10.25 10 10.5 10.25 10.5 10.5V14.8438L16.5938 13.3125C16.8438 13.2812 16.9688 13.0625 16.9688 12.8438V10.9062L18 10.625ZM14.4688 4.1875C14.375 4.4375 14.0938 4.5625 13.8125 4.5C13.5625 4.40625 13.4375 4.125 13.5 3.84375L14.5 0.84375C14.5938 0.59375 14.875 0.46875 15.1562 0.53125C15.4062 0.625 15.5312 0.90625 15.4688 1.1875L14.4688 4.1875Z"
      fill="black"
    />
  </Icon>
);

export default BoxFull;
