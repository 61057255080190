import {
  AWAITING_ADDRESS,
  CUSTOMIZE_EDUCATION,
  CONFIRMATION,
  CUSTOMIZING,
  PERSONAL_SURVEY,
  USER_ONBOARDING_CUSTOMIZING,
  USER_ONBOARDING_XSELLS,
  XSELLS,
} from './constants/newMemberStates';

// If user is in X state, the user will be redirected to the first URL in the array representing that state, unless
// the user is on one of the pages in the array
export const newMemberStateUrlMap = [
  {
    name: [AWAITING_ADDRESS],
    nmUrls: ['/gtb-thank-you/shipping', '/gtb-thank-you/shipping-v2'],
    rmUrls: ['/reactivate-thanks', '/my-account-v3/reactivate-thanks'],
  },
  {
    name: [CUSTOMIZE_EDUCATION, PERSONAL_SURVEY],
    nmUrls: ['/personal-survey'],
    rmUrls: ['/personal-survey'],
  },
  {
    name: [CUSTOMIZING, USER_ONBOARDING_CUSTOMIZING],
    nmUrls: ['/new-member-customize', '/new-member-customize-v2'],
    rmUrls: [
      '/reactivation-customize',
      '/reactivate-customize',
      '/reactivation-customize-v2',
    ],
  },
  {
    name: [XSELLS, USER_ONBOARDING_XSELLS],
    nmUrls: ['/new-member/boosts-q1', '/new-member/boosts-q2'],
    rmUrls: ['/new-member/boosts-q1', '/new-member/boosts-q2'],
  },
  {
    name: [CONFIRMATION],
    nmUrls: [
      '/new-member-customize/confirmation',
      '/new-member-customize-v2/confirmation',
    ],
    rmUrls: [
      '/reactivation-customize/confirmation',
      '/reactivate-customize/confirmation',
      '/reactivation-customize-v2/confirmation',
    ],
  },
];

export const findNewMemberStateIndex = (newMemberState) => {
  const lookForState = (mapItem) => mapItem.name.includes(newMemberState);
  return newMemberStateUrlMap.findIndex(lookForState);
};
