import { Fallbacks } from '../types';
import ADD_ONS_FALLBACKS from './add-ons';
import CUSTOMIZE_FALLBACKS from './customize';
import EDIT_FALLBACKS from './edit';
import HOMEPAGE_FALLBACKS from './homepage';
import UPGRADE_CONFIRMATION_FALLBACKS from './upgrade-confirmation';
import SHOP_FALLBACKS from './shop';
import GIFTING_FALLBACKS from './gifting';

const fallbacks: Fallbacks = {
  ...ADD_ONS_FALLBACKS,
  ...CUSTOMIZE_FALLBACKS,
  ...EDIT_FALLBACKS,
  ...HOMEPAGE_FALLBACKS,
  ...UPGRADE_CONFIRMATION_FALLBACKS,
  ...SHOP_FALLBACKS,
  ...GIFTING_FALLBACKS
};

export default fallbacks;
