import React from 'react';
import ReactDOM from 'react-dom';

import CartContainer from './scenes/Cart/CartContainer';
import CartContextProvider, { useCartContext } from './context/CartContext';
import {
  getCartSubtotal,
  getAllCreditValues,
  getShipping,
  getCartTotal,
  getTaxLineItems,
} from './services/utils/cartCalculations';
import {
  flattenProducts,
  getCartVariants,
  getCount,
  getVariantQuantityLimits,
  getSocialCartProducts,
  getSocialCartVariants,
} from './services/utils/formatResponse';
import { formatCurrencyInCents, convertToDollars, formatCurrency } from './services/utils/currency';
import InternalContextProvider from './context/InternalContext';

if (
  (process.env.APP_ENV === 'local' || process.env.APP_ENV === 'dev') &&
  typeof global?.window !== 'undefined'
) {
  ReactDOM.render(
    <InternalContextProvider env={process.env.APP_ENV}>
      <CartContainer />
    </InternalContextProvider>,
    global.window?.document?.getElementById('root')
  );
}

const CartComponent = ({ env, newMemberState, onNewMemberStateFetched }) => (
  <InternalContextProvider env={env}>
    <CartContainer
      newMemberState={newMemberState}
      onNewMemberStateFetched={onNewMemberStateFetched}
    />
  </InternalContextProvider>
);

export {
  getCartSubtotal,
  getAllCreditValues,
  getShipping,
  flattenProducts,
  getCartVariants,
  getCount,
  formatCurrencyInCents,
  getVariantQuantityLimits,
  CartContextProvider,
  useCartContext,
  getSocialCartProducts,
  getSocialCartVariants,
  getCartTotal,
  convertToDollars,
  formatCurrency,
  getTaxLineItems,
  CartComponent,
};

export default CartComponent;
