import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import styles from './ProspectHeaderContainer.module.css';
import { createTrackingData, MENU_LABELS } from '../../utils/prospectGlobalNav';
import { ProspectNavContext } from '../../context/ProspectNavContext';

export const ProspectNavItems = ({ routes }) => {
  const { segmentTrackingData } = useContext(ProspectNavContext);

  return routes.map((route) => {
    const key = `${route.label}`;
    const linkClass = styles.link;

    const ctaName = route.label;

    const handleClick = () => {
      if (route.onClick) {
        route.onClick();
      }

      createTrackingData({
        ctaName,
        segmentTrackingData,
      });

      if (route.label === MENU_LABELS.membership) {
        window.location.href = route.url;
      }
    };

    return (
      <li className={styles.listItem} key={key} role="menuitem">
        {route.onClick ? (
          <button type="button" onClick={handleClick} className={linkClass}>
            {ctaName}
          </button>
        ) : (
          <a href={route.url} onClick={handleClick} className={linkClass}>
            {route.label}
          </a>
        )}
      </li>
    );
  });
};

const routePropTypes = PropTypes.arrayOf(
  PropTypes.shape({
    label: PropTypes.string.isRequired,
    url: PropTypes.string,
    onClick: PropTypes.func,
  }),
);

ProspectNavItems.propTypes = {
  routes: routePropTypes.isRequired,
};
