// eslint-disable-next-line import/prefer-default-export
export const CART_TYPE = {
  social: 'social',
  sale: 'sale',
  shop: 'shop',
};

export const PLAN_TYPES = {
  seasonal: 'fffvip',
  annual: 'fffvipa',
  trial: 'ffftrial',
  social: 'fffsocial',
};

export const SUBSCRIPTION_STATE = {
  expired: 'EXPIRED',
  canceled: 'CANCELED',
};

export const VARIANT_TYPES = {
  refill: 'REFILL',
  intro: 'INTRO',
  prospectSingle: 'PROSPECT_SINGLE',
  customizeAdditionalChoice: 'CUSTOMIZE_ADDITIONAL_CHOICE',
};
