import { SALE_TYPES } from '../services/utils/campaign';
import { isPreviewingCampaign } from '../services/utils/page';

export const TABS = {
  SEASON: {
    id: 'add-ons',
    name: 'Add-Ons',
  },
  EDIT: {
    id: 'edit',
    name: 'Edit Sale',
  },
  FLASH: {
    id: 'flash-sales',
    name: 'Flash Sales',
  },
  SHOP: {
    id: 'shop',
    name: 'The Shop',
  },
  SOCIAL: {
    id: 'social',
  },
  SOCIAL_EDIT: {
    id: 'social-edit',
    name: 'My Cart',
  },
  UPCOMING: {
    id: 'upcoming',
    name: 'Upcoming Order',
  },
};

export const getSocialTabName = (teamCount) => {
  if (teamCount > 1) return `${teamCount} Friends' Carts`;
  if (teamCount === 1) return `1 Friend's Cart`;
  return "Friends' Carts";
};

export const getCampaign = (activeCampaign, displaySocialCart, isNewMemberOnboarding = false) => {
  if (activeCampaign && isPreviewingCampaign) return TABS[activeCampaign.type];
  if (isNewMemberOnboarding && activeCampaign?.type === SALE_TYPES.EDIT) return TABS.UPCOMING;
  if (activeCampaign && activeCampaign.type === SALE_TYPES.SEASON) return TABS.SEASON;
  if (activeCampaign && activeCampaign.type === SALE_TYPES.EDIT) {
    if (displaySocialCart) {
      return TABS.SOCIAL_EDIT;
    }
    return TABS.EDIT;
  }

  return TABS.FLASH;
};

export const getSecondCampaign = (displaySocialCart, teamCount) => {
  if (displaySocialCart) {
    return {
      ...TABS.SOCIAL,
      name: getSocialTabName(teamCount),
    };
  }
  return TABS.SHOP;
};
