import React from 'react';
import Toast from '@fff-web/fff-ui-shared/Toast';
import useExpirationToast from '../../hooks/useExpirationToast';

const NavToast = () => {
  const { snoozeToast } = useExpirationToast();

  return <Toast onCloseButton={snoozeToast} />;
};

export default NavToast;
