import some from 'lodash/some';

export const PAGE = {
  'add-ons': 'add-ons',
  shop: 'shop',
  edit: 'edit',
  boosts: 'boosts',
  'new-member-boosts': 'new-member-boosts',
  'new-member-customize': 'new-member-customize',
};

const { pathname } = global.window?.location || {};

export const getPage = () => {
  if (pathname.includes('add-ons')) {
    return 'add-ons';
  }
  if (pathname.includes('shop')) {
    return 'shop';
  }
  if (pathname.includes('edit')) {
    return 'edit';
  }
  if (pathname.includes('new-member/boosts')) {
    return 'new-member-boosts';
  }
  if (pathname.includes('boosts')) {
    return 'boosts';
  }
  if (pathname.includes('new-member-customize')) {
    return 'new-member-customize';
  }
  return 'fff';
};

const pandoraPages = [
  'add-ons',
  'boosts',
  'edit',
  'shop',
  'exclusive-offers',
  'product',
  'my-account',
  'redeem-invite',
  'new-member-boosts',
];

export const getIsInPandora = () => some(pandoraPages, (page) => pathname.includes(page));

// only used for previewing campaigns - this is an optional parameter for all requests
export const getCampaignIdQueryParam = () => {
  let url = '';
  if (global.window?.location.href) {
    url = new URL(global.window?.location.href).searchParams.get('campaign_id');
  }
  return url;
};

export const isPreviewingCampaign = !!getCampaignIdQueryParam();

export const getCampaignStatusQueryParam = () => {
  let url = '';
  if (global.window?.location.href) {
    url = new URL(global.window?.location.href).searchParams.get('campaign_status');
  }
  return url;
};
